module.exports = [
  { label: "DXY", ticker: "DXY", pipDistance: 0.001, session: 0 },

  { label: "Forex", ticker: "", pipDistance: 0, multiplier: 0, session: 0 },

  {
    label: "AUD/CAD",
    ticker: "AUDCAD",
    pipDistance: 0.0001,
    multiplier: 1,
    oanda: "AUD_CAD",
    session: "1900-0500",
  },
  {
    label: "AUD/CHF",
    ticker: "AUDCHF",
    pipDistance: 0.0001,
    multiplier: 1,
    oanda: "AUD_CHF",
    session: "1900-0500",
  },
  {
    label: "AUD/JPY",
    ticker: "AUDJPY",
    pipDistance: 0.01,
    multiplier: 1,
    oanda: "AUD_JPY",
    session: "1900-0500",
  },
  {
    label: "AUD/NZD",
    ticker: "AUDNZD",
    pipDistance: 0.0001,
    multiplier: 1,
    oanda: "AUD_NZD",
    session: "1900-0500",
  },
  {
    label: "AUD/USD",
    ticker: "AUDUSD",
    pipDistance: 0.0001,
    multiplier: 1,
    oanda: "AUD_USD",
    session: "1900-0500",
  },
  {
    label: "CAD/JPY",
    ticker: "CADJPY",
    pipDistance: 0.01,
    multiplier: 1,
    oanda: "CAD_JPY",
    session: "1200-2100",
  },
  {
    label: "CAD/CHF",
    ticker: "CADCHF",
    pipDistance: 0.0001,
    multiplier: 1,
    oanda: "CAD_CHF",
    session: "1200-2100",
  },
  {
    label: "CHF/JPY",
    ticker: "CHFJPY",
    pipDistance: 0.01,
    multiplier: 1,
    oanda: "CHF_JPY",
    session: "0700-1600",
  },
  {
    label: "GBP/AUD",
    ticker: "GBPAUD",
    pipDistance: 0.0001,
    multiplier: 1,
    oanda: "GBP_AUD",
    session: "0700-1700",
  },
  {
    label: "GBP/CAD",
    ticker: "GBPCAD",
    pipDistance: 0.0001,
    multiplier: 1,
    oanda: "GBP_CAD",
    session: "0700-1700",
  },
  {
    label: "GBP/CHF",
    ticker: "GBPCHF",
    pipDistance: 0.0001,
    multiplier: 1,
    oanda: "GBP_CHF",
    session: "0700-1700",
  },
  {
    label: "GBP/JPY",
    ticker: "GBPJPY",
    pipDistance: 0.01,
    multiplier: 1,
    oanda: "GBP_JPY",
    session: "0700-1700",
  },
  {
    label: "GBP/NZD",
    ticker: "GBPNZD",
    pipDistance: 0.0001,
    multiplier: 1,
    oanda: "GBP_NZD",
    session: "0700-1700",
  },
  {
    label: "GBP/USD",
    ticker: "GBPUSD",
    pipDistance: 0.0001,
    multiplier: 1,
    oanda: "GBP_USD",
    session: "0700-1700",
  },
  {
    label: "EUR/USD",
    ticker: "EURUSD",
    pipDistance: 0.0001,
    multiplier: 1,
    oanda: "EUR_USD",
    session: "0700-1600",
  },
  {
    label: "EUR/AUD",
    ticker: "EURAUD",
    pipDistance: 0.0001,
    multiplier: 1,
    oanda: "EUR_AUD",
    session: "0700-1700",
  },
  {
    label: "EUR/CAD",
    ticker: "EURCAD",
    pipDistance: 0.0001,
    multiplier: 1,
    oanda: "EUR_CAD",
    session: "0700-1600",
  },
  {
    label: "EUR/GBP",
    ticker: "EURGBP",
    pipDistance: 0.0001,
    multiplier: 1,
    oanda: "EUR_GBP",
    session: "0700-1600",
  },
  {
    label: "EUR/JPY",
    ticker: "EURJPY",
    pipDistance: 0.01,
    multiplier: 1,
    oanda: "EUR_JPY",
    session: "0700-1600",
  },
  {
    label: "EUR/CHF",
    ticker: "EURCHF",
    pipDistance: 0.0001,
    multiplier: 1,
    oanda: "EUR_CHF",
    session: "0700-1600",
  },
  {
    label: "EUR/NZD",
    ticker: "EURNZD",
    pipDistance: 0.0001,
    multiplier: 1,
    oanda: "EUR_NZD",
    session: "0700-1700",
  },
  {
    label: "NZD/CAD",
    ticker: "NZDCAD",
    pipDistance: 0.0001,
    multiplier: 1,
    oanda: "NZD_CAD",
    session: "1900-0500",
  },
  {
    label: "NZD/CHF",
    ticker: "NZDCHF",
    pipDistance: 0.0001,
    multiplier: 1,
    oanda: "NZD_CHF",
    session: "1900-0500",
  },
  {
    label: "NZD/JPY",
    ticker: "NZDJPY",
    pipDistance: 0.01,
    multiplier: 1,
    oanda: "NZD_JPY",
    session: "1900-0500",
  },
  {
    label: "NZD/USD",
    ticker: "NZDUSD",
    pipDistance: 0.0001,
    multiplier: 1,
    oanda: "NZD_USD",
    session: "1900-0500",
  },
  {
    label: "USD/CHF",
    ticker: "USDCHF",
    pipDistance: 0.0001,
    multiplier: 1,
    oanda: "USD_CHF",
    session: "1200-2100",
  },
  {
    label: "USD/JPY",
    ticker: "USDJPY",
    pipDistance: 0.01,
    multiplier: 1,
    oanda: "USD_JPY",
    session: "1200-2100",
  },
  {
    label: "USD/CAD",
    ticker: "USDCAD",
    pipDistance: 0.0001,
    multiplier: 1,
    oanda: "USD_CAD",
    session: "1200-2100",
  },

  { label: "Indices", ticker: "", pipDistance: 0, multiplier: 0, session: 0 },

  {
    label: "AUS200",
    ticker: "AUS200",
    pipDistance: 0.1,
    multiplier: 10,
    baseCurrency: "AUD",
    session: "0000-0900",
  },
  {
    label: "CHN50",
    ticker: "CHN50",
    pipDistance: 0.1,
    multiplier: 10,
    baseCurrency: "USD",
    session: "0130-0830",
  },
  {
    label: "ESP35",
    ticker: "ESP35",
    pipDistance: 0.1,
    multiplier: 10,
    baseCurrency: "EUR",
    session: "0700-1600",
  },
  {
    label: "EUSTX50",
    ticker: "EUSTX50",
    pipDistance: 0.1,
    multiplier: 10,
    baseCurrency: "EUR",
    session: "0700-1600",
  },
  {
    label: "FRA40",
    ticker: "FRA40",
    pipDistance: 0.1,
    multiplier: 10,
    baseCurrency: "EUR",
    session: "0700-1600",
  },
  {
    label: "GER30",
    ticker: "GER30",
    pipDistance: 0.1,
    multiplier: 10,
    baseCurrency: "EUR",
    session: "0700-1600",
  },
  {
    label: "HKG33",
    ticker: "HKG33",
    pipDistance: 0.1,
    multiplier: 10,
    baseCurrency: "HKD",
    session: "0130-0800",
  },
  {
    label: "JPN225",
    ticker: "JPN225",
    pipDistance: 0.1,
    multiplier: 10,
    baseCurrency: "JPY",
    session: "0000-0600",
  },
  {
    label: "NAS100",
    ticker: "NAS100",
    pipDistance: 0.1,
    multiplier: 10,
    baseCurrency: "USD",
    session: "1430-2100",
  },
  {
    label: "SPX500",
    ticker: "SPX500",
    pipDistance: 0.1,
    multiplier: 10,
    baseCurrency: "USD",
    session: "1430-2100",
  },
  {
    label: "UK100",
    ticker: "UK100",
    pipDistance: 0.1,
    multiplier: 10,
    baseCurrency: "GBP",
    session: "0800-1630",
  },
  {
    label: "US2000",
    ticker: "US2000",
    pipDistance: 0.1,
    multiplier: 10,
    baseCurrency: "USD",
    session: "1430-2100",
  },
  {
    label: "US30",
    ticker: "US30",
    pipDistance: 0.1,
    multiplier: 10,
    baseCurrency: "USD",
    session: "1430-2100",
  },

  {
    label: "Commodities",
    ticker: "",
    pipDistance: 0,
    multiplier: 0,
    session: 0,
  },

  {
    label: "COFFEE",
    ticker: "COFFEENYF",
    pipDistance: 0.1,
    multiplier: 10,
    commoditie: true,
    session: "1400-2000",
  },
  {
    label: "COPPER",
    ticker: "COPPER",
    pipDistance: 0.1,
    multiplier: 1000,
    commoditie: true,
    session: "0030-1800",
  },
  {
    label: "CORN",
    ticker: "CORNF",
    pipDistance: 0.1,
    multiplier: 10,
    commoditie: true,
    session: "1200-1900",
  },
  {
    label: "GOLD",
    ticker: "XAUUSD",
    pipDistance: 0.1,
    multiplier: 10,
    session: "0000-2300",
  },
  {
    label: "NGAS",
    ticker: "NGAS",
    pipDistance: 0.1,
    multiplier: 1000,
    commoditie: true,
    session: "0030-2100",
  },
  {
    label: "WHEAT",
    ticker: "WHEATF",
    pipDistance: 0.1,
    multiplier: 10,
    commoditie: true,
    session: "1200-1900",
  },
  {
    label: "SILVER",
    ticker: "XAGUSD",
    pipDistance: 0.1,
    multiplier: 100,
    commoditie: true,
    session: "0000-2300",
  },
  {
    label: "SOY",
    ticker: "SOYF",
    pipDistance: 0.1,
    multiplier: 10,
    commoditie: true,
    session: "1200-1900",
  },
  {
    label: "SUGAR",
    ticker: "SUGARNYF",
    pipDistance: 0.1,
    multiplier: 10,
    commoditie: true,
    session: "1400-2000",
  },
  {
    label: "UKOIL",
    ticker: "UKOIL",
    pipDistance: 0.1,
    multiplier: 100,
    commoditie: true,
    session: "0100-2200",
  },
  {
    label: "USOIL",
    ticker: "USOIL",
    pipDistance: 0.1,
    multiplier: 100,
    commoditie: true,
    session: "0000-2300",
  },

  { label: "Crypto", ticker: "", pipDistance: 0, multiplier: 0, session: 0 },

  {
    label: "BTC/USD",
    ticker: "BTCUSD",
    pipDistance: 0.1,
    multiplier: 10,
    crypto: true,
    session: "1300-2200",
  },
  {
    label: "ETH/USD",
    ticker: "ETHUSD",
    pipDistance: 0.1,
    multiplier: 10,
    crypto: true,
    session: "1300-2200",
  },
];

import useWindowDimensions from "../../utils/width";

const TradeAnalysis = ({ trades, runSpeficCal, theme }) => {
  const { width } = useWindowDimensions();

  const win = "#26a65d";
  const loss = "#f23645";
  const breakeven = "#ED9E0E";

  return (
    <div
      style={{
        padding: "0.7em",
        borderRadius: "10px",
        width: "100%",
        maxWidth: "770px",
      }}
    >
      <table
      className={theme[0] === "dark" ? 'tableHoverEffectDark' : 'tableHoverEffectLight'}
        style={{
          tableLayout: "fixed",
          borderCollapse: "collapse",
          width: "100%",
          height: "200px",
          fontSize: width <= 700 ? "13px" : "18px",
          margin: "0 auto",
        }}
      >
        <caption
          style={{
            marginBottom: "10px",
            fontSize: width <= 700 ? "16px" : "18px",
            fontWeight: "400",
            borderBottom: `1px solid ${
              theme[0] === "dark" ? "#FCFCFF" : "black"
            }`,
            paddingBottom: "10px",
          }}
        >
          Trade Analysis
        </caption>
        <thead
        className="tableHoverEffectOff"
          style={{ fontSize: width <= 700 ? "13px" : "16px", height: "50px" }}
        >
          <tr>
            <th></th>
            <th style={{ fontWeight: "400", color: win }}>Win</th>
            <th style={{ fontWeight: "400", color: breakeven }}>BE</th>
            <th style={{ fontWeight: "400", color: loss }}>Loss</th>
            <th style={{ fontWeight: "400" }}># Trades</th>
            <th style={{ fontWeight: "400" }}>Return %</th>
          </tr>
        </thead>
        <tbody>
          <tr style={{ textAlign: "center" }}>
            <th style={{ fontWeight: "400" }} scope="row">
              High Risk
            </th>
            <td style={{ color: win }}>
              {
                trades.filter(
                  (t) =>
                    t.grade === "High Risk" &&
                    (t.outcomePC === "Win" || t.outcomeIC === "Win")
                ).length
              }
            </td>
            <td style={{ color: breakeven }}>
              {
                trades.filter(
                  (t) =>
                    t.grade === "High Risk" &&
                    (t.outcomePC === "Breakeven" || t.outcomeIC === "Breakeven")
                ).length
              }
            </td>
            <td style={{ color: loss }}>
              {
                trades.filter(
                  (t) =>
                    t.grade === "High Risk" &&
                    (t.outcomePC === "Loss" || t.outcomeIC === "Loss")
                ).length
              }
            </td>
            <td>{trades.filter((t) => t.grade === "High Risk").length}</td>

            {runSpeficCal(trades.filter((t) => t.grade === "High Risk"))}
          </tr>

          <tr style={{ textAlign: "center" }}>
            <th style={{ fontWeight: "400" }} scope="row">
              Low Risk
            </th>

            <td style={{ color: win }}>
              {
                trades.filter(
                  (t) =>
                    t.grade === "Low Risk" &&
                    (t.outcomePC === "Win" || t.outcomeIC === "Win")
                ).length
              }
            </td>
            <td style={{ color: breakeven }}>
              {
                trades.filter(
                  (t) =>
                    t.grade === "Low Risk" &&
                    (t.outcomePC === "Breakeven" || t.outcomeIC === "Breakeven")
                ).length
              }
            </td>
            <td style={{ color: loss }}>
              {
                trades.filter(
                  (t) =>
                    t.grade === "Low Risk" &&
                    (t.outcomePC === "Loss" || t.outcomeIC === "Loss")
                ).length
              }
            </td>

            <td>{trades.filter((t) => t.grade === "Low Risk").length}</td>
            {runSpeficCal(trades.filter((t) => t.grade === "Low Risk"))}
          </tr>

          <tr style={{ textAlign: "center" }}>
            <th style={{ fontWeight: "400" }} scope="row">
              Total
            </th>
            <td style={{ color: win }}>
              {
                trades.filter(
                  (t) =>
                    (t.grade === "High Risk" || t.grade === "Low Risk") &&
                    (t.outcomePC === "Win" || t.outcomeIC === "Win")
                ).length
              }
            </td>
            <td style={{ color: breakeven }}>
              {
                trades.filter(
                  (t) =>
                    (t.grade === "High Risk" || t.grade === "Low Risk") &&
                    (t.outcomePC === "Breakeven" || t.outcomeIC === "Breakeven")
                ).length
              }
            </td>
            <td style={{ color: loss }}>
              {
                trades.filter(
                  (t) =>
                    (t.grade === "High Risk" || t.grade === "Low Risk") &&
                    (t.outcomePC === "Loss" || t.outcomeIC === "Loss")
                ).length
              }
            </td>
            <td> {trades.filter((t) => t.grade).length}</td>
            {runSpeficCal(trades)}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default TradeAnalysis;
import { React, useContext } from "react";

import ThemeContext from "../../lib/theme-context";
import AuthContext from "../../lib/auth-context";
import { DataGrid } from "@mui/x-data-grid";

const CustomDataGrid = ({
  handleEditCell,
  showSpecficTest,
  setToRemove,
  columns,
  handleRemoveButton,
  inProgressMode,
  viewMode,
}) => {
  const theme = useContext(ThemeContext);
  const auth = useContext(AuthContext);

  const backtestingMode = auth.backtestingMode;

  return (
    <DataGrid
     
      sx={{
        "& .MuiSvgIcon-root": {
          color: theme[0] === "dark" ? "#FCFCFF" : "black",
        },

        "& .MuiDataGrid-virtualScroller": {
          height: "auto !important",
        },

        backgroundColor: theme[0] === "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",

        color: theme[0] === "dark" ? "#FCFCFF" : "black",

        "& .MuiDataGrid-cell": {
          cursor: "pointer",
        },
        "& .MuiDataGrid-row--lastVisible": {
          borderBottom:
            theme[0] !== "dark"
              ? "1px solid rgba(40, 42, 46)"
              : " 1px solid #FCFCFF",
        },
        "& .MuiDataGrid-checkboxInput": {
          color: theme[0] === "dark" ? "#FCFCFF" : "black",
        },

        "& .MuiTablePagination-displayedRows": {
          color: theme[0] === "dark" ? "#FCFCFF" : "black",
        },
        "& .MuiTablePagination-actions": {
          color: theme[0] === "dark" ? "#FCFCFF" : "black",
          background: "1px solid red",
        },
        "& .MuiDataGrid-row:hover": {
          backgroundColor: theme[0] === "dark" ? 'rgb(90, 90, 90)' : 'rgb(210, 210, 210)' 
        },
        "& .Mui-selected": {
          backgroundColor: "rgba(0, 73, 251, 0.30) !important",    
        }
      }}
      onCellEditCommit={(params, o) => {
        return handleEditCell(params);
      }}
      // onStateChange={(state) => {
      //   console.log("rows", state.filter.filteredRowsLookup);
      // }}
      // sort my earliest trades first
      rows={
        !backtestingMode && inProgressMode
          ? auth.allTrades
              .filter((trades) => trades.id?.includes("live-"))
              .filter(
                (trades) =>
                  trades.outcomePC?.includes("In Progress") ||
                  trades.outcomeIC?.includes("In Progress")
              )
              .reverse()
          : !backtestingMode && !inProgressMode
          ? auth.allTrades
              .filter((trades) => trades.id?.includes("live-"))

              .reverse()
          : backtestingMode
          ? auth.allTrades
              .filter(
                (trades) =>
                  trades.testId?.includes(showSpecficTest) &&
                  trades.id?.includes("backtest-")
              )
              .reverse()
          : auth.allTrades
              .filter((trades) => trades.id?.includes("backtest-"))
              .reverse()
      }
      columns={columns}
      autoPageSize
      rowHeight={55}
      checkboxSelection={
        localStorage.getItem("view-mode") === "all-view"
          ? true
          : localStorage.getItem("view-mode") === "simple-view"
          ? false
          : false
      }
      // checkboxSelection
      disableSelectionOnClick
      onSelectionModelChange={(ids, other) => {
        const selectedIDs = new Set(ids);
        const selectedRowData = auth.allTrades.filter((row) =>
          selectedIDs.has(row.id.toString())
        );
        setToRemove(selectedRowData);
        handleRemoveButton(selectedRowData);
      }}
    />
  );
};

export default CustomDataGrid;

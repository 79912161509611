import { React, useContext } from "react";
import TextField from "@mui/material/TextField";
import ThemeContext from "../../lib/theme-context";

const CustomSingleTextField = ({
  variant,
  type,
  size,
  label,
  value,
  onChange,
  onBlur,
  inputProps,
  slotProps
}) => {
  const theme = useContext(ThemeContext);

  return (
    <TextField
       slotProps={slotProps}
      inputProps={inputProps}
      variant={variant}
      type={type}
      size={size}
      label={label}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      fullWidth
      InputLabelProps={{
        style: {
          color: theme[0] === "dark" ? "#FCFCFF" : "black",
        },
      }}
      sx={{
        marginRight: "1em",
        "& fieldset": {
          // - The <fieldset> inside the Input-root
          borderColor:
            theme[0] === "dark" ? "#FCFCFF" : "rgba(40, 42, 46)"  , // - Set the Input border
        },
        "&:hover fieldset": {
          borderColor:
            theme[0] === "dark" ? "#FCFCFF" : "rgba(40, 42, 46)"  , // - Set the Input border when parent has :hover
        },
        "&.Mui-focused fieldset": {
          // - Set the Input border when parent is focused
          borderColor:
            theme[0] === "dark" ? "#FCFCFF" : "rgba(40, 42, 46)"  ,
        },
        input: {
          color: theme[0] === "dark" ? "#FCFCFF" : "black",
        },

        "& label": {
          // - The <fieldset> inside the Input-root
          borderColor:
            theme[0] === "dark" ? "#FCFCFF" : "rgba(40, 42, 46)"  , // - Set the Input border
        },
        "&:hover label": {
          borderColor:
            theme[0] === "dark" ? "#FCFCFF" : "rgba(40, 42, 46)"  , // - Set the Input border when parent has :hover
        },
        "&.Mui-focused label": {
          // - Set the Input border when parent is focused
          borderColor:
            theme[0] === "dark" ? "#FCFCFF" : "rgba(40, 42, 46)"  ,
        },
      }}
    />
  );
};

export default CustomSingleTextField;

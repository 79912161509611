import { useContext, useEffect, useState } from "react";
import ThemeContext from "../../lib/theme-context";
import AuthContext from "../../lib/auth-context";
import Monthly from "./monthly";
import Weekly from "./weekly";
import Quarterly from "./quarterly";
import CustomButtom from "../buttons/";
import moment from "moment";
import Yearly from "./yearly";

import SavingsOutlinedIcon from "@mui/icons-material/SavingsOutlined";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import JoinRightOutlinedIcon from "@mui/icons-material/JoinRightOutlined";

import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CloseIcon from "@mui/icons-material/Close";
import Tooltip from "@mui/material/Tooltip";

const EquityCurve = ({
  calculateFields,
  trades,
  equityCurve,
  setEquityCurve,
}) => {
  const theme = useContext(ThemeContext);
  const auth = useContext(AuthContext);
  const [years, setYears] = useState([]);
  const mode = localStorage.getItem("equity-mode");
  const year = moment().format("Y");
  /* eslint-disable no-unused-vars */
  const [selected, setSelected] = useState(0);
  const [selectedTimeframe, setSelectedTimeframe] = useState(0);
  const timeframes = ["Weekly", "Monthly", "Quarterly", "Yearly"];
  const [headingText, setHeadingText] = useState();

  useEffect(() => {
    setHeadingText(
      localStorage.getItem("year") !== "0"
        ? localStorage.getItem("year")
        : "All"
    );

    if (localStorage.getItem("year") === "0") {
      setSelectedTimeframe(3);
    }

    let all_trades = trades;
    if (auth.backtestingMode) {
      all_trades = trades.filter((tests) => tests?.id?.includes("backtest-"));
    } else {
      all_trades = trades.filter((tests) => tests?.id?.includes("live-"));
    }
    all_trades = all_trades.map((trade) => {
      return moment(trade.entryDate, "DD/MM/YYYY").format("Y");
    });
    all_trades = [...new Set(all_trades)];
    setYears(all_trades);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeYear = () => {
    const year = localStorage.getItem("year");

    if (year === "0") {
      setHeadingText("2024");
      localStorage.setItem("year", JSON.stringify(2024));
      handleChangeTimeframe(true, 0);
    } else if (year === "2024") {
      setHeadingText("2025");
      localStorage.setItem("year", JSON.stringify(2025));
      handleChangeTimeframe(true, 0);
    } else if (year === "2025") {
      setHeadingText("0");
      localStorage.setItem("year", JSON.stringify(0));
      handleChangeTimeframe(true, 3);
    }

    calculateFields(auth.trades, auth.backtestingMode);
  };

  const handleChangeTimeframe = (overRide, n) => {
    if (overRide) {
      setSelectedTimeframe(n);
    } else {
      setSelectedTimeframe((prev) => {
        if (prev === 2) {
          return 0;
        } else {
          if (prev === timeframes.length - 1) {
            return 0;
          } else {
            return prev + 1;
          }
        }
      });
    }
  };

  const handleMode = () => {
    if (mode === "Personal Capital") {
      localStorage.setItem("equity-mode", "Investor Capital");
      auth.setEquityTypeMode("Investor Capital");
    } else if (mode === "Investor Capital") {
      localStorage.setItem("equity-mode", "Personal & Investor Capital");
      auth.setEquityTypeMode("Personal & Investor Capital");
    } else if (mode === "Personal & Investor Capital") {
      localStorage.setItem("equity-mode", "Personal Capital");
      auth.setEquityTypeMode("Personal Capital");
    }
    calculateFields(
      auth.trades,
      auth.backtestingMode,
      auth.backtestingMode ? localStorage.getItem("backtest-active") : ""
    );
  };

  const setColor = () => {
    const mode = localStorage.getItem("equity-mode");
    switch (mode) {
      case "Personal Capital":
        return (
          <SavingsOutlinedIcon
            fontSize="large"
            sx={{ color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF" }}
          />
        );
      case "Investor Capital":
        return (
          <AccountBalanceOutlinedIcon
            fontSize="large"
            sx={{ color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF" }}
          />
        );
      case "Personal & Investor Capital":
        return (
          <JoinRightOutlinedIcon
            sx={{
              color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
              transform: "rotate(180deg)",
              fontSize: "35px",
            }}
          />
        );
      default:
        return (
          <MoreVertIcon
            fontSize="large"
            sx={{ color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF" }}
          />
        );
    }
  };
  return (
    <div
      style={{
        minHeight: "100%",
        width: "100%",
        backgroundColor: theme[0] === "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
      }}
    >
      <div
        style={{
          width: "100%",
          padding: "0 1em 1em 1em",
        }}
      >
        <div
          style={{
            margin: "-1.5em 0 0 0",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div>
            <IconButton
              sx={{
                color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
                marginRight: "5px",
              }}
              onClick={handleMode}
            >
              {setColor()}
            </IconButton>
            <CustomButtom
              variant="outlined"
              label={headingText !== "0" ? headingText : "All"}
              onClick={() => handleChangeYear()}
            />
            <CustomButtom
              variant="outlined"
              label={timeframes[selectedTimeframe]}
              onClick={() => {
                if (localStorage.getItem("year") === "0") {
                  handleChangeTimeframe(true, 3);
                } else {
                  handleChangeTimeframe();
                }
              }}
            />
          </div>

          <Tooltip
            title="Close"
            sx={{
              color: theme[0] === "dark" ? "#FCFCFF" : "black",
              cursor: "pointer",
            }}
          >
            <CloseIcon
              sx={{
                color: theme[0] === "dark" ? "#FCFCFF" : "black",
                cursor: "pointer",
                fontSize: "35px",
              }}
              fontSize="large"
              onClick={() => {
                localStorage.setItem("equityCurve", equityCurve ? false : true);
                setEquityCurve(equityCurve ? false : true);
              }}
            />
          </Tooltip>
        </div>
        <br />
        {selectedTimeframe === 0 ? (
          <Weekly
            calculateFields={calculateFields}
            theme={theme}
            years={years[selected]}
            selected={selected}
            year={year}
          />
        ) : selectedTimeframe === 1 ? (
          <Monthly
            calculateFields={calculateFields}
            theme={theme}
            years={years[selected]}
            selected={selected}
            year={year}
          />
        ) : selectedTimeframe === 2 ? (
          <Quarterly
            calculateFields={calculateFields}
            theme={theme}
            years={years[selected]}
            selected={selected}
            year={year}
          />
        ) : selectedTimeframe === 3 ? (
          <Yearly
            calculateFields={calculateFields}
            theme={theme}
            years={years[selected]}
            selected={selected}
            year={year}
          />
        ) : null}
      </div>
    </div>
  );
};

export default EquityCurve;

import markets from "./markets";
import DoneIcon from "@mui/icons-material/Done";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
// import moment from "moment-timezone";
// import WatchIcon from "@mui/icons-material/Watch";
// import WatchOffIcon from "@mui/icons-material/WatchOff";

import CustomSelect from "../components/select/dataGridList";
import CustomDateTimePicker from "../components/dateTimePickers/DateTimePicker";

import { DataFetcher } from "../utils/dataFetcher";
import moment from "moment";

const ColumnsComplex = (
  theme,
  throwMessage,
  idTradeGenerator,
  runDurationCal,
  handleOpenJournal,
  auth,
  calculateFields
) => {
  const checkStopLossType = (p) => {
    const pairObject = markets.find((m) => m.ticker === p);
    if (pairObject?.multiplier === 10) {
      return "Ticks";
    } else if (pairObject?.multiplier === 1) {
      return "Pips";
    }
  };

  // const checkSession = (p) => {
  //   const found = markets.find((m) => m.ticker === p.row.pair);
  //   let session = found.session;

  //   let entryDate = p.row.entryDate.split(/\//);
  //   let exitDate = p.row.exitDate.split(/\//);

  //   entryDate = [entryDate[1], entryDate[0], entryDate[2]].join("/");
  //   exitDate = [exitDate[1], exitDate[0], exitDate[2]].join("/");

  //   const date = {
  //     entryDate: entryDate,
  //     entryTime: p.row.entryTime,
  //     exitDate: exitDate,
  //     exitTime: p.row.exitTime,
  //   };

  //   let startDate = moment(new Date(date.entryDate + " " + date.entryTime)).tz(
  //     p.row.timezone
  //   );

  //   let start;
  //   let end;

  //   startDate = startDate.utc().format();

  //   startDate = startDate.split("T")[1].split(":");

  //   startDate = startDate[0] + startDate[1];

  //   console.log("found", p.row.pair);
  //   console.log("startDate", startDate);

  //   const current_time = moment().utc().format();

  //   const UKDaylightSavings = moment.tz(current_time, "Europe/London").isDST();

  //   if (!UKDaylightSavings) {
  //     start = moment(session.split("-")[0], "HH:mm")
  //       .subtract(1, "hours")
  //       .format("HH:mm");
  //     end = moment(session.split("-")[1], "HH:mm")
  //       .subtract(1, "hours")
  //       .format("HH:mm");
  //   } else {
  //     start = moment(session.split("-")[0], "HH:mm")
  //       .add(1, "hours")
  //       .format("HH:mm");
  //     end = moment(session.split("-")[1], "HH:mm")
  //       .add(1, "hours")
  //       .format("HH:mm");
  //   }

  //   if (startDate >= start && startDate <= end) {
  //     return "True";
  //   } else {
  //     return "False";
  //   }

  //   // console.log(session.split("-")[0])
  //   // console.log(session.split("-")[1])
  // };

  const handleEditCell = async (cell) => {
    const runFunction = async () => {
      const result = await DataFetcher("edit-trade", cell);
      if (result) {
        auth.setTrades([]);
        auth.setAllTrades([]);
        const filteredArray = auth.trades.filter((x) => x._id !== result._id);
        filteredArray.push(result);
        filteredArray.sort((a, b) => {
          return moment(a.created).diff(b.created);
        });
        auth.setTrades(filteredArray);

        if (!auth.backtestingMode) {
          calculateFields(filteredArray, false);
        } else {
          calculateFields(filteredArray, true, result.testId);
        }
      } else {
        throwMessage("error", "Error Try Again");
      }
    };

    const pc_array = [
      "closePricePC",
      "openPricePC",
      "stopLossPipsPC",
      "feePC",
      "profitLossDollarPersonal",
    ];
    const ic_array = [
      "closePriceIC",
      "openPriceIC",
      "stopLossPipsIC",
      "feeIC",
      "profitLossDollarInvestor",
    ];

    if (cell.id) {
      const entireCell = auth.trades.find((t) => t.id === cell.id);
      const mode = localStorage.getItem("equity-mode");
      if (mode === "Personal & Investor Capital") {
        if (entireCell.equityType === "Investor Capital") {
          if (!pc_array.includes(cell.field)) {
            runFunction();
          }
        } else if (entireCell.equityType === "Personal Capital") {
          if (!ic_array.includes(cell.field)) {
            runFunction();
          }
        } else if (entireCell.equityType === "Personal & Investor Capital") {
          runFunction();
        }
      } else {
        runFunction();
      }
    } else {
      throwMessage("error", "Error Try Again");
    }
  };
  const runUpdate = (e, params, date) => {
    const id = params.row.id;
    const field = params.field;
    if (date) {
      const payload = { value: date, id: id, field: field };
      handleEditCell(payload);
    } else {
      const value = e.target.value;
      const payload = { value: value, id: id, field: field };
      handleEditCell(payload);
    }
  };

  let array = [
    {
      field: "pair",
      headerName: "Instrument",
      width: 110,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        if (!params.row.pair) {
          return "";
        } else {
          return (
            <p
              style={{
                color: theme[0] === "dark" ? "#FCFCFF" : "black",
              }}
            >
              {params.row.pair}
            </p>
          );
        }
      },
    },
    {
      field: "journalID",
      headerName: "Journal",
      width: 95,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        if (!params.row.pair) {
          return "";
        } else {
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                width: "100%",
                margin: "0 15px 0 0",
              }}
            >
              <p
                style={{ cursor: "pointer", margin: "0 0 0 10px" }}
                onClick={() => {
                  if (!params.row.journalID) {
                    throwMessage("error", "No Journal Found");
                    handleOpenJournal(params.row, true);
                  } else {
                    handleOpenJournal(params.row, true);
                  }
                }}
              >
                {params.row.journalComplete === "true" ? (
                  <DoneIcon
                    style={{
                      color: "26a65d",
                    }}
                  />
                ) : (
                  <MenuBookIcon
                    style={{
                      color: "#f23645",
                    }}
                  />
                )}
              </p>
            </div>
          );
        }
      },
    },
    {
      field: "foreCastId",
      headerName: "Forecast",
      width: 93,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              width: "100%",
              margin: "0 15px 0 0",
            }}
          >
            {params.row.foreCastId === "Empty" ? (
              <HorizontalRuleIcon
                sx={{ color: theme[0] === "dark" ? "#FCFCFF" : "black" }}
              />
            ) : params.row.foreCastId ? (
              <p
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  localStorage.setItem(
                    "forecast-to-view",
                    params.row.foreCastId
                  );
                  localStorage.setItem("screen", 0);
                  auth.setTab(0);
                  localStorage.setItem("from-tracker", true);
                }}
              >
                <DoneIcon
                  style={{
                    color: "26a65d",
                  }}
                />
              </p>
            ) : (
              <QueryStatsIcon
                style={{
                  color: "#f23645",
                }}
              />
            )}
          </div>
        );
      },
    },
    {
      field: "direction",
      headerName: "Direction",
      width: 110,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <CustomSelect
            color={
              params.row.direction === "Short"
                ? "#db0671"
                : params.row.direction === "Long"
                ? "#0079cf"
                : ""
            }
            size="small"
            inputLabel=""
            value={params.row.direction}
            label={""}
            onChange={(e) => runUpdate(e, params)}
            menuItemArray={JSON.stringify([
              { value: "Short", label: "Short" },
              { value: "Long", label: "Long" },
            ])}
          />
        );
      },
    },
    {
      field: "outcomePC",
      headerName: "Outcome PC",
      width: 105,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        if (!params.row.outcomePC) {
          return (
            <HorizontalRuleIcon
              sx={{ color: theme[0] === "dark" ? "#FCFCFF" : "black" }}
            />
          );
        } else {
          return (
            <p
              style={{
                color:
                  params.row.outcomePC === "Loss"
                    ? "#f23645"
                    : params.row.outcomePC === "Win"
                    ? "#26a65d"
                    : params.row.outcomePC === "Breakeven"
                    ? "#ED9E0E"
                    : "",
              }}
            >
              {params.row.outcomePC}
            </p>
          );
        }
      },
    },
    {
      field: "outcomeIC",
      headerName: "Outcome IC",
      width: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        if (!params.row.outcomeIC) {
          return (
            <HorizontalRuleIcon
              sx={{ color: theme[0] === "dark" ? "#FCFCFF" : "black" }}
            />
          );
        } else {
          return (
            <p
              style={{
                color:
                  params.row.outcomeIC === "Loss"
                    ? "#f23645"
                    : params.row.outcomeIC === "Win"
                    ? "#26a65d"
                    : params.row.outcomeIC === "Breakeven"
                    ? "#ED9E0E"
                    : "",
              }}
            >
              {params.row.outcomeIC}
            </p>
          );
        }
      },
    },
    {
      field: "grade",
      headerName: "Grade",
      width: 90,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <CustomSelect
            color={
              params.row.grade === "Low Risk"
                ? "#26a65d"
                : params.row.grade === "High Risk"
                ? "#f23645"
                : ""
            }
            size="small"
            inputLabel=""
            value={params.row.grade}
            label={""}
            onChange={(e) => runUpdate(e, params)}
            menuItemArray={JSON.stringify([
              { value: "Low Risk", label: "Low Risk" },
              { value: "High Risk", label: "High Risk" },
            ])}
          />
        );
      },
    },
    {
      field: "optionType",
      headerName: "Option Type",
      width: 110,
      headerAlign: "center",
      align: "center",
      editable: true,
      renderCell: (params) => {
        return (
          <CustomSelect
            color={
              params.row.optionType === "Call"
                ? "#0079cf"
                : params.row.optionType === "Put"
                ? "#db0671"
                : ""
            }
            size="small"
            inputLabel=""
            value={params.row.optionType}
            label={""}
            onChange={(e) => runUpdate(e, params)}
            menuItemArray={JSON.stringify([
              { value: "Put", label: "Put" },
              { value: "Call", label: "Call" },
            ])}
          />
        );
      },
    },
    {
      field: "premium",
      headerName: "Premium",
      width: 110,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        if (!params.row.premium) {
          return "";
        } else {
          return (
            <p
              style={{
                color: theme[0] === "dark" ? "#FCFCFF" : "black",
              }}
            >
              {params.row.premium}
            </p>
          );
        }
      },
    },


    {
      field: "riskOptions",
      headerName: "Risk",
      width: 110,
      headerAlign: "center",
      align: "center",
      editable: true,
      renderCell: (params) => {
        if (!params.row.riskOptions) {
          return "";
        } else {
          return (
            <p
              style={{
                color: theme[0] === "dark" ? "#FCFCFF" : "black",
              }}
            >
              {params.row.riskOptions}
            </p>
          );
        }
      },
    },
    {
      field: "strike",
      headerName: "Strike",
      width: 110,
      headerAlign: "center",
      align: "center",
      editable: true,
      renderCell: (params) => {
        if (!params.row.strike) {
          return "";
        } else {
          return (
            <p
              style={{
                color: theme[0] === "dark" ? "#FCFCFF" : "black",
              }}
            >
              {params.row.strike}
            </p>
          );
        }
      },
    },

    {
      field: "expiry",
      headerName: "Expiry",
      width: 210,
      headerAlign: "center",
      align: "center",
      editable: true,
      renderCell: (params) => {
        if (!params.row.expiry) {
          return "";
        } else {
          return (
            <CustomDateTimePicker
              defaultValue={moment(new Date(params.row.expiry)).format(
                "HH:MM DD/MM/YY"
              )}
              size="small"
              label=""
              value={params.row.expiry}
              onChange={(e) => runUpdate(e, params, e.$d)}
              border="none"
            />
          );
        }
      },
    },
    {
      field: "quantity",
      headerName: "Quantity",
      width: 110,
      headerAlign: "center",
      align: "center",
      editable: true,
      renderCell: (params) => {
        if (!params.row.quantity) {
          return "";
        } else {
          return (
            <p
              style={{
                color: theme[0] === "dark" ? "#FCFCFF" : "black",
              }}
            >
              {params.row.quantity}
            </p>
          );
        }
      },
    },
    {
      field: "feeOptions",
      headerName: "Fee",
      width: 90,
      headerAlign: "center",
      align: "center",
      editable: true,
      renderCell: (params) => {
        return (
          <p
            style={{
              color:
                params.row.feeOptions < 0
                  ? "#f23645"
                  : params.row.feeOptions > 0
                  ? "#26a65d"
                  : "#ED9E0E",
            }}
          >
            {Math.abs(params.row.feeOptions)}
          </p>
        );
      },
    },
    {
      field: "openPriceOptions",
      headerName: "Open Price",
      width: 110,
      headerAlign: "center",
      align: "center",
      editable: true,
      renderCell: (params) => {
        if (!params.row.openPriceOptions) {
          return "";
        } else {
          return (
            <p
              style={{
                color: theme[0] === "dark" ? "#FCFCFF" : "black",
              }}
            >
              {params.row.openPriceOptions}
            </p>
          );
        }
      },
    },
    {
      field: "closePriceOptions",
      headerName: "Close Price",
      width: 110,
      headerAlign: "center",
      align: "center",
      editable: true,
      renderCell: (params) => {
        if (!params.row.closePriceOptions) {
          return "";
        } else {
          return (
            <p
              style={{
                color: theme[0] === "dark" ? "#FCFCFF" : "black",
              }}
            >
              {params.row.closePriceOptions}
            </p>
          );
        }
      },
    },

    {
      field: "feePC",
      headerName: "Fee PC",
      width: 90,
      headerAlign: "center",
      align: "center",
      editable: true,
      renderCell: (params) => {
        if (
          params.row.equityType === "Personal & Investor Capital" ||
          params.row.equityType === "Personal Capital" ||
          params.row.equityType === "Backtest"
        ) {
          if (!params.row.feePC) {
            return <p style={{ color: "#ED9E0E" }}>£ 0</p>;
          } else {
            return (
              <p
                style={{
                  color:
                    params.row.feePC < 0
                      ? "#f23645"
                      : params.row.feePC > 0
                      ? "#26a65d"
                      : "#ED9E0E",
                }}
              >
                £ {Math.abs(params.row.feePC)}
              </p>
            );
          }
        } else {
          return (
            <HorizontalRuleIcon
              sx={{ color: theme[0] === "dark" ? "#FCFCFF" : "black" }}
            />
          );
        }
      },
    },
    {
      field: "feeIC",
      headerName: "Fee IC",
      width: 90,
      headerAlign: "center",
      align: "center",
      editable: true,
      renderCell: (params) => {
        if (
          params.row.equityType === "Personal & Investor Capital" ||
          params.row.equityType === "Investor Capital"
        ) {
          if (!params.row.feeIC) {
            return <p style={{ color: "#ED9E0E" }}>$ 0</p>;
          } else {
            return (
              <p
                style={{
                  color:
                    params.row.feeIC < 0
                      ? "#f23645"
                      : params.row.feeIC > 0
                      ? "#26a65d"
                      : "#ED9E0E",
                }}
              >
                $ {Math.abs(params.row.feeIC)}
              </p>
            );
          }
        } else {
          return (
            <HorizontalRuleIcon
              sx={{ color: theme[0] === "dark" ? "#FCFCFF" : "black" }}
            />
          );
        }
      },
    },
    {
      field: "profitLossPercentagePC",
      headerName: "% PC",
      width: 85,
      valueFormatter: ({ value }) => `${value} %`,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        // "Personal & Investor Capital"
        if (
          params.row.equityType === "Personal & Investor Capital" ||
          params.row.equityType === "Personal Capital" ||
          params.row.equityType === "Backtest"
        ) {
          if (!params.row.profitLossPercentagePC) {
            return <p style={{ color: "#ED9E0E" }}>0 %</p>;
          } else {
            return (
              <p
                style={{
                  color:
                    params.row.profitLossPercentagePC < 0
                      ? "#f23645"
                      : params.row.profitLossPercentagePC > 0
                      ? "#26a65d"
                      : "#ED9E0E",
                }}
              >
                {Math.abs(params.row.profitLossPercentagePC)} %
              </p>
            );
          }
        } else {
          return (
            <HorizontalRuleIcon
              sx={{ color: theme[0] === "dark" ? "#FCFCFF" : "black" }}
            />
          );
        }
      },
    },
    {
      field: "profitLossDollarPersonal",
      headerName: "£ PC",
      width: 85,
      headerAlign: "center",
      align: "center",
      editable: true,
      renderCell: (params) => {
        if (
          params.row.equityType === "Personal & Investor Capital" ||
          params.row.equityType === "Personal Capital" ||
          params.row.equityType === "Backtest"
        ) {
          if (!params.row.profitLossDollarPersonal) {
            return <p style={{ color: "#ED9E0E" }}>£ 0</p>;
          } else {
            return (
              <p
                style={{
                  color:
                    params.row.profitLossDollarPersonal < 0
                      ? "#f23645"
                      : params.row.profitLossDollarPersonal > 0
                      ? "#26a65d"
                      : "#ED9E0E",
                }}
              >
                £ {Math.abs(params.row.profitLossDollarPersonal)}
              </p>
            );
          }
        } else {
          return (
            <HorizontalRuleIcon
              sx={{ color: theme[0] === "dark" ? "#FCFCFF" : "black" }}
            />
          );
        }
      },
    },

    {
      field: "profitLossPercentageIC",
      headerName: "% IC",
      width: 85,
      valueFormatter: ({ value }) => `${value} %`,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        if (
          params.row.equityType === "Personal & Investor Capital" ||
          params.row.equityType === "Investor Capital"
        ) {
          if (!params.row.profitLossPercentageIC) {
            return <p style={{ color: "#ED9E0E" }}>0 %</p>;
          } else {
            return (
              <p
                style={{
                  color:
                    params.row.profitLossPercentageIC < 0
                      ? "#f23645"
                      : params.row.profitLossPercentageIC > 0
                      ? "#26a65d"
                      : "#ED9E0E",
                }}
              >
                {Math.abs(params.row.profitLossPercentageIC)} %
              </p>
            );
          }
        } else {
          return (
            <HorizontalRuleIcon
              sx={{ color: theme[0] === "dark" ? "#FCFCFF" : "black" }}
            />
          );
        }
      },
    },
    {
      field: "profitLossDollarInvestor",
      headerName: "$ IC",
      width: 85,
      headerAlign: "center",
      align: "center",
      editable: true,
      renderCell: (params) => {
        if (
          params.row.equityType === "Personal & Investor Capital" ||
          params.row.equityType === "Investor Capital"
        ) {
          if (!params.row.profitLossDollarInvestor) {
            return <p style={{ color: "#ED9E0E" }}>$ 0</p>;
          } else {
            return (
              <p
                style={{
                  color:
                    params.row.profitLossDollarInvestor < 0
                      ? "#f23645"
                      : params.row.profitLossDollarInvestor > 0
                      ? "#26a65d"
                      : "#ED9E0E",
                }}
              >
                $ {Math.abs(params.row.profitLossDollarInvestor)}
              </p>
            );
          }
        } else {
          return (
            <HorizontalRuleIcon
              sx={{ color: theme[0] === "dark" ? "#FCFCFF" : "black" }}
            />
          );
        }
      },
    },

    {
      field: "returnTotalsPersonal",
      headerName: "P-R-R",
      width: 130,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        if (
          params.row.equityType === "Personal & Investor Capital" ||
          params.row.equityType === "Personal Capital" ||
          params.row.equityType === "Backtest"
        ) {
          if (!params.row.returnTotalsPersonal) {
            return <p style={{ color: "#ED9E0E" }}>$ 0</p>;
          } else {
            return (
              <p
                style={{
                  color:
                    params.row.returnTotalsPersonal < 0
                      ? "#f23645"
                      : params.row.returnTotalsPersonal > 0
                      ? "#26a65d"
                      : "#ED9E0E",
                }}
              >
                £ {Math.abs(params.row.returnTotalsPersonal)}
              </p>
            );
          }
        } else {
          return (
            <HorizontalRuleIcon
              sx={{ color: theme[0] === "dark" ? "#FCFCFF" : "black" }}
            />
          );
        }
      },
    },
    {
      field: "returnTotalsInvestor",
      headerName: "I-R-R",
      width: 130,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        if (
          params.row.equityType === "Personal & Investor Capital" ||
          params.row.equityType === "Investor Capital"
        ) {
          if (!params.row.returnTotalsInvestor) {
            return <p style={{ color: "#ED9E0E" }}>$ 0</p>;
          } else {
            return (
              <p
                style={{
                  color:
                    params.row.returnTotalsInvestor < 0
                      ? "#f23645"
                      : params.row.returnTotalsInvestor > 0
                      ? "#26a65d"
                      : "#ED9E0E",
                }}
              >
                $ {Math.abs(params.row.returnTotalsInvestor)}
              </p>
            );
          }
        } else {
          return (
            <HorizontalRuleIcon
              sx={{ color: theme[0] === "dark" ? "#FCFCFF" : "black" }}
            />
          );
        }
      },
    },

    {
      field: "rollingBalanceIC",
      headerName: "Rolling Balance IC",
      width: 140,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        if (
          params.row.equityType === "Investor Capital" ||
          params.row.equityType === "Personal & Investor Capital"
        ) {
          if (!params.row.rollingBalanceIC) {
            return <p style={{ color: "#ED9E0E" }}>$ 0</p>;
          } else {
            return (
              <p
                style={{
                  color:
                    params.row.rollingBalanceIC <
                    Number(
                      JSON.parse(localStorage.getItem("user")).startingBalanceIC
                    )
                      ? "#f23645"
                      : params.row.rollingBalanceIC >
                        Number(
                          JSON.parse(localStorage.getItem("user"))
                            .startingBalanceIC
                        )
                      ? "#26a65d"
                      : "#ED9E0E",
                }}
              >
                $ {Math.abs(params.row.rollingBalanceIC)}
              </p>
            );
          }
        } else {
          return (
            <HorizontalRuleIcon
              sx={{ color: theme[0] === "dark" ? "#FCFCFF" : "black" }}
            />
          );
        }
      },
    },

    {
      field: "rollingBalancePC",
      headerName: "Rolling Balance PC",
      width: 140,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        if (
          params.row.equityType === "Personal Capital" ||
          params.row.equityType === "Personal & Investor Capital"
        ) {
          if (!params.row.rollingBalancePC) {
            return <p style={{ color: "#ED9E0E" }}>$ 0</p>;
          } else {
            return (
              <p
                style={{
                  color:
                    params.row.rollingBalancePC <
                    Number(
                      JSON.parse(localStorage.getItem("user")).startingBalancePC
                    )
                      ? "#f23645"
                      : params.row.rollingBalancePC >
                        Number(
                          JSON.parse(localStorage.getItem("user"))
                            .startingBalancePC
                        )
                      ? "#26a65d"
                      : "#ED9E0E",
                }}
              >
                $ {Math.abs(params.row.rollingBalancePC)}
              </p>
            );
          }
        } else {
          return (
            <HorizontalRuleIcon
              sx={{ color: theme[0] === "dark" ? "#FCFCFF" : "black" }}
            />
          );
        }
      },
    },

    {
      field: "stopLossPipsPC",
      headerName: "Stop Loss PC",
      width: 120,
      headerAlign: "center",
      align: "center",
      editable: true,
      renderCell: (params) => {
        if (
          params.row.equityType === "Personal & Investor Capital" ||
          params.row.equityType === "Personal Capital" ||
          params.row.equityType === "Backtest"
        ) {
          if (!params.row.stopLossPipsPC) {
            return "";
          } else {
            return (
              <p>
                {params.row.stopLossPipsPC} {checkStopLossType(params.row.pair)}
              </p>
            );
          }
        } else {
          return (
            <HorizontalRuleIcon
              sx={{ color: theme[0] === "dark" ? "#FCFCFF" : "black" }}
            />
          );
        }
      },
    },

    {
      field: "stopLossPipsIC",
      headerName: "Stop Loss IC",
      width: 120,
      headerAlign: "center",
      align: "center",
      editable: true,
      renderCell: (params) => {
        if (
          params.row.equityType === "Personal & Investor Capital" ||
          params.row.equityType === "Investor Capital"
        ) {
          if (!params.row.stopLossPipsIC) {
            return "";
          } else {
            return (
              <p>
                {params.row.stopLossPipsIC} {checkStopLossType(params.row.pair)}
              </p>
            );
          }
        } else {
          return (
            <HorizontalRuleIcon
              sx={{ color: theme[0] === "dark" ? "#FCFCFF" : "black" }}
            />
          );
        }
      },
    },

    // {
    //   field: "session",
    //   headerName: "Session",
    //   width: 90,
    //   headerAlign: "center",
    //   align: "center",

    //   renderCell: (params) => {
    //     const result = checkSession(params);
    //     return result === "True" ? (
    //       <WatchIcon
    //         style={{
    //           color: "#26a65d",
    //         }}
    //       />
    //     ) : result === "False" ? (
    //       <WatchOffIcon
    //         style={{
    //           color: "#f23645",
    //         }}
    //       />
    //     ) : (
    //       ""
    //     );
    //   },
    // },

    {
      field: "entryDate",
      headerName: "Entry Date",
      width: 95,
      headerAlign: "center",
      align: "center",
      // editable: true,
    },
    {
      field: "entryTime",
      headerName: "Entry Time",
      width: 95,
      headerAlign: "center",
      align: "center",
      // editable: true,
    },
    {
      field: "exitDate",
      headerName: "Exit Date",
      width: 95,
      headerAlign: "center",
      align: "center",
      editable: true,
    },

    {
      field: "exitTime",
      headerName: "Exit Time",
      width: 95,
      headerAlign: "center",
      align: "center",
      editable: true,
    },

    {
      field: "duration",
      headerName: "Duration",
      width: 90,
      headerAlign: "center",
      align: "center",

      renderCell: (params) => {
        return runDurationCal(params);
      },
    },

    {
      field: "openPricePC",
      headerName: "Open PC",
      width: 85,
      editable: true,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        if (
          params.row.equityType === "Personal & Investor Capital" ||
          params.row.equityType === "Personal Capital" ||
          params.row.equityType === "Backtest"
        ) {
          if (!params.row.openPricePC) {
            return "";
          } else {
            return <p>{params.row.openPricePC}</p>;
          }
        } else {
          return (
            <HorizontalRuleIcon
              sx={{ color: theme[0] === "dark" ? "#FCFCFF" : "black" }}
            />
          );
        }
      },
    },
    {
      field: "closePricePC",
      headerName: "Close PC",
      width: 85,
      editable: true,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        if (
          params.row.equityType === "Personal & Investor Capital" ||
          params.row.equityType === "Personal Capital" ||
          params.row.equityType === "Backtest"
        ) {
          if (!params.row.closePricePC) {
            return "";
          } else {
            return <p>{params.row.closePricePC}</p>;
          }
        } else {
          return (
            <HorizontalRuleIcon
              sx={{ color: theme[0] === "dark" ? "#FCFCFF" : "black" }}
            />
          );
        }
      },
    },
    {
      field: "openPriceIC",
      headerName: "Open IC",
      width: 85,
      editable: true,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        if (
          params.row.equityType === "Personal & Investor Capital" ||
          params.row.equityType === "Investor Capital"
        ) {
          if (!params.row.openPriceIC) {
            return "";
          } else {
            return <p>{params.row.openPriceIC}</p>;
          }
        } else {
          return (
            <HorizontalRuleIcon
              sx={{ color: theme[0] === "dark" ? "#FCFCFF" : "black" }}
            />
          );
        }
      },
    },

    {
      field: "closePriceIC",
      headerName: "Close IC",
      width: 85,
      editable: true,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        if (
          params.row.equityType === "Personal & Investor Capital" ||
          params.row.equityType === "Investor Capital"
        ) {
          if (!params.row.closePriceIC) {
            return "";
          } else {
            return <p>{params.row.closePriceIC}</p>;
          }
        } else {
          return (
            <HorizontalRuleIcon
              sx={{ color: theme[0] === "dark" ? "#FCFCFF" : "black" }}
            />
          );
        }
      },
    },
    {
      field: "ro3",
      headerName: "Rule Of 3",
      width: 110,
      headerAlign: "center",
      align: "center",
      editable: true,
      renderCell: (params) => {
        return (
          <CustomSelect
            size="small"
            inputLabel=""
            value={params.row.ro3}
            label={""}
            onChange={(e) => runUpdate(e, params)}
            menuItemArray={JSON.stringify([
              { value: "Impulsive", label: "Impulsive" },
              { value: "Corrective", label: "Corrective" },
              { value: "Structural", label: "Structural" },
            ])}
          />
        );
      },
    },
    {
      field: "entryType",
      headerName: "Entry Type",
      width: 110,
      headerAlign: "center",
      align: "center",
      editable: true,
      renderCell: (params) => {
        return (
          <CustomSelect
            size="small"
            inputLabel=""
            value={params.row.entryType}
            label={""}
            onChange={(e) => runUpdate(e, params)}
            menuItemArray={JSON.stringify([
              { value: "Risk", label: "Risk" },
              { value: "Reduced Risk", label: "Reduced Risk" },
            ])}
          />
        );
      },
    },
    {
      field: "highlightedPair",
      headerName: "Watchlist",
      width: 110,
      headerAlign: "center",
      align: "center",
      editable: true,
      renderCell: (params) => {
        return (
          <CustomSelect
            size="small"
            inputLabel=""
            value={params.row.highlightedPair}
            label={""}
            onChange={(e) => runUpdate(e, params)}
            menuItemArray={JSON.stringify([
              { value: "Top", label: "Top" },
              { value: "Additional", label: "Additional" },
              { value: "None", label: "None" },
            ])}
          />
        );
      },
    },
    {
      field: "firstEntryOrScaleIn",
      headerName: "Entry No",
      width: 110,
      headerAlign: "center",
      align: "center",
      editable: true,
      renderCell: (params) => {
        return (
          <CustomSelect
            size="small"
            inputLabel=""
            value={params.row.firstEntryOrScaleIn}
            label={""}
            onChange={(e) => runUpdate(e, params)}
            menuItemArray={JSON.stringify([
              { value: "1st Entry", label: "First Entry" },
              { value: "Scale In", label: "Scale In" },
            ])}
          />
        );
      },
    },
    {
      field: "pattern",
      headerName: "Pattern",
      width: 220,
      headerAlign: "center",
      align: "center",
      editable: true,
      renderCell: (params) => {
        return (
          <CustomSelect
            size="small"
            inputLabel=""
            value={params.row.pattern}
            label={""}
            onChange={(e) => runUpdate(e, params)}
            menuItemArray={JSON.stringify([
              { value: "2 Touch Bear Flag", label: "2 Touch Bear Flag" },
              { value: "3 Touch Bear Flag", label: "3 Touch Bear Flag" },
              {
                value: " 2 Touch Flat Bear Flag",
                label: " 2 Touch Flat Bear Flag",
              },
              {
                value: "       3 Touch Flat Bear Flag",
                label: "       3 Touch Flat Bear Flag",
              },
              {
                value: "Symmetrical Bear Triangle",
                label: "Symmetrical Bear Triangle",
              },
              {
                value: "Expanding Bear Triangle",
                label: "Expanding Bear Triangle",
              },
              { value: "Advanced Bear Flag", label: "Advanced Bear Flag" },
              { value: "Ascending Channel", label: "Ascending Channel" },
              { value: "Rising Wedge", label: "Rising Wedge" },
              { value: "Head & Shoulders", label: "Head & Shoulders" },
              { value: "Double Top", label: "Double Top" },
              { value: "Triple Top", label: "Triple Top" },
              { value: " 2 Touch Bull Flag", label: " 2 Touch Bull Flag" },
              { value: "3 Touch Bull Flag", label: "3 Touch Bull Flag" },
              {
                value: " 2 Touch Flat Bull Flag",
                label: " 2 Touch Flat Bull Flag",
              },
              {
                value: "3 Touch Flat Bull Flag",
                label: "3 Touch Flat Bull Flag",
              },
              {
                value: "Symmetrical Bull Triangle",
                label: "Symmetrical Bull Triangle",
              },
              {
                value: "Expanding Bull Triangle",
                label: "Expanding Bull Triangle",
              },
              { value: "Advanced Bull Flag", label: "Advanced Bull Flag" },
              { value: "Descending Channel", label: "Descending Channel" },
              { value: "Falling Wedge", label: "Falling Wedge" },
              {
                value: "Inverse Head & Shoulders",
                label: "Inverse Head & Shoulders",
              },
              { value: "Double Bottom", label: "Double Bottom" },
              { value: "Triple Bottom", label: "Triple Bottom" },
            ])}
          />
        );
      },
    },

    {
      field: "timezone",
      headerName: "Timezone",
      width: 120,
      headerAlign: "center",
      align: "center",
      // editable: true,
      renderCell: (params) => {
        if (!params.row.timezone) {
          return "";
        } else {
          return <p>{params.row.timezone}</p>;
        }
      },
    },

    {
      field: "newTransactionIC",
      headerName: "Transaction IC",
      width: 130,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        if (
          params.row.equityType === "Investor Capital" ||
          params.row.equityType === "Personal & Investor Capital"
        ) {
          if (!params.row.newTransactionIC) {
            return "";
          } else {
            return <p>{params.row.newTransactionIC}</p>;
          }
        } else {
          return (
            <HorizontalRuleIcon
              sx={{ color: theme[0] === "dark" ? "#FCFCFF" : "black" }}
            />
          );
        }
      },
    },

    {
      field: "transanctionNotesIC",
      headerName: "Note IC",
      width: 300,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        if (
          params.row.equityType === "Investor Capital" ||
          params.row.equityType === "Personal & Investor Capital"
        ) {
          if (!params.row.transanctionNotesIC) {
            return "";
          } else {
            return <p>{params.row.transanctionNotesIC}</p>;
          }
        } else {
          return (
            <HorizontalRuleIcon
              sx={{ color: theme[0] === "dark" ? "#FCFCFF" : "black" }}
            />
          );
        }
      },
    },

    {
      field: "newTransactionPC",
      headerName: "Transaction PC",
      width: 130,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        if (
          params.row.equityType === "Personal Capital" ||
          params.row.equityType === "Personal & Investor Capital"
        ) {
          if (!params.row.newTransactionPC) {
            return "";
          } else {
            return <p>{params.row.newTransactionPC}</p>;
          }
        } else {
          return (
            <HorizontalRuleIcon
              sx={{ color: theme[0] === "dark" ? "#FCFCFF" : "black" }}
            />
          );
        }
      },
    },

    {
      field: "transanctionNotesPC",
      headerName: "Note PC",
      width: 300,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        if (
          params.row.equityType === "Personal Capital" ||
          params.row.equityType === "Personal & Investor Capital"
        ) {
          if (!params.row.transanctionNotesPC) {
            return "";
          } else {
            return <p>{params.row.transanctionNotesPC}</p>;
          }
        } else {
          return (
            <HorizontalRuleIcon
              sx={{ color: theme[0] === "dark" ? "#FCFCFF" : "black" }}
            />
          );
        }
      },
    },
  ];

  const mode = localStorage.getItem("equity-mode");

  const PC_Fields = [
        'riskOptions',
    "expiry",
    "optionType",
    "premium",
    "strike",
    "quantity",
    "closePriceOptions",
    "openPriceOptions",
    "outcomePC",
    "profitLossPercentagePC",
    "profitLossDollarPersonal",
    "feePC",
    "returnTotalsPersonal",
    "stopLossPipsPC",
    "openPricePC",
    "closePricePC",
    "rollingBalancePC",
    "newTransactionPC",
    "transanctionNotesPC",
  ];
  const IC_Fields = [
    'riskOptions',
    "expiry",
    "optionType",
    "premium",
    "strike",
    "quantity",
    "closePriceOptions",
    "openPriceOptions",
    "outcomeIC",
    "profitLossPercentageIC",
    "profitLossDollarInvestor",
    "feeIC",
    "returnTotalsInvestor",
    "stopLossPipsIC",
    "openPriceIC",
    "closePriceIC",
    "rollingBalanceIC",
    "newTransactionIC",
    "transanctionNotesIC",
  ];
  const OPTIONS_Fields = [
    "firstEntryOrScaleIn",
    "entryType",
    "outcomePC",
    "profitLossPercentagePC",
    "profitLossDollarPersonal",
    "feePC",
    "returnTotalsPersonal",
    "stopLossPipsPC",
    "openPricePC",
    "closePricePC",
    "rollingBalancePC",
    "newTransactionPC",
    "transanctionNotesPC",
    "outcomeIC",
    "profitLossPercentageIC",
    "profitLossDollarInvestor",
    "feeIC",
    "returnTotalsInvestor",
    "stopLossPipsIC",
    "openPriceIC",
    "closePriceIC",
    "rollingBalanceIC",
    "newTransactionIC",
    "transanctionNotesIC",
  ];
  const ICPC_Fields = [
    "optionType",
    "premium",
    "strike",
    "quantity",
    "closePriceOptions",
    "openPriceOptions",
    'riskOptions',
    "expiry",
  ];

  if (mode === "Personal Capital") {
    let filteredData;
    filteredData = array.filter((obj) => !IC_Fields.includes(obj.field));
    array = filteredData;
    return array;
  } else if (mode === "Investor Capital") {
    let filteredData;
    filteredData = array.filter((obj) => !PC_Fields.includes(obj.field));
    array = filteredData;
    return array;
  } else if (mode === "Options") {
    let filteredData;
    filteredData = array.filter((obj) => !OPTIONS_Fields.includes(obj.field));
    array = filteredData;
    return array;
  } else {
    let filteredData;
    filteredData = array.filter((obj) => !ICPC_Fields.includes(obj.field));
    array = filteredData;
    return array;
  }
};

export default ColumnsComplex;

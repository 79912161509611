import Modal from "@mui/material/Modal";
import React, { useState, useContext, useEffect } from "react";
import Grid from "@mui/material/Grid";
import ThemeContext from "../../lib/theme-context";

import MenuItem from "@mui/material/MenuItem";
import ListSubheader from "@mui/material/ListSubheader";
import CustomSingleTextField from "../../components/textfields/singleRow";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import CustomSelect from "../../components/select/index";
import markets from "../../lib/markets";
import CustomButtom from "../../components/buttons/";
import oandaConnect from "../../services/oanda";
import useWindowDimensions from "../../utils/width";

import CloseIcon from "@mui/icons-material/Close";
import Tooltip from "@mui/material/Tooltip";
import throwMessage from "../../utils/throwMessage";
import AuthContext from "../../lib/auth-context";

const fx = require("simple-fxtrade");

const Cal = ({ open, onClose }) => {
  useEffect(() => {
    document.body.style.overflow = open ? "hidden" : "unset";
  }, [open]);

  const auth = useContext(AuthContext);
  const { width } = useWindowDimensions();
  const theme = useContext(ThemeContext);
  // State for user inputs
  const [accountBalance, setAccountBalance] = useState(0); // Default balance
  const [risk, setRisk] = useState(1); // Default risk % per trade
  const [stopLossPips, setStopLossPips] = useState(0); // Default SL in pips
  const [pair, setPair] = useState("");
  const [depositCurrency, setDepositCurrency] = useState("USD");
  const [contractSize, setContractSize] = useState(100000);

  const [exchangeRate, setExchangeRate] = useState(0);

  const [lots, setLots] = useState(0);
  const [units, setUnits] = useState(0);
  const [moneyRisk, setMoneyRisk] = useState(0);
  const [exchangeLabel, setExchangeLabel] = useState("");
  const [customContractSize, setCustomContractSize] = useState(false);

  const [hide, setHide] = useState(false);

  const moneySymbol = ["$", "£"];

  const style = { color: theme[0] === "dark" ? "#FCFCFF" : "rgba(40, 42, 46)" };

  useEffect(() => {
    setAccountBalance(
      localStorage.getItem("equity-mode") === "Personal Capital"
        ? auth.realisedBalancePC
        : localStorage.getItem("equity-mode") === "Investor Capital"
        ? auth.realisedBalanceIC
        : 0
    );
    oandaConnect();
  }, [auth.realisedBalancePC, auth.realisedBalanceIC]);

  const externalAPICall = async (pair) => {
    return fetch(
      `https://v6.exchangerate-api.com/v6/${process.env.REACT_APP_FREE_API_KEY}/latest/${pair}`,
      {
        method: "GET",
        // mode: "no-cors",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((response) => {
      if (response.status === 200) {
        return response.json();
      } else {
        const error = { error: `error with status ${response.status}` };
        throw error;
      }
    });
  };
  const runExternalAPICall = async (baseCurrency) => {
    return externalAPICall(baseCurrency)
      .then((result) => {
        return result.conversion_rates;
      })
      .catch((error) => {
        console.log(error);
        return [];
      });
  };
  const clearFields = () => {
    setLots(0);
    setUnits(0);
    setMoneyRisk(0);
  };
  const runOandaFunc = async (pair) => {
    const found = markets.find((p) => p.ticker === pair);
    if (found.multiplier === 1) {
      runForexCals(pair, found);
    } else if (found?.baseCurrency) {
      runIndicesCals(pair, found);
    } else if (found?.commoditie || found?.crypto) {
      runCommoditiesCals(pair, found);
    }
  };
  const runIndicesCals = async (pair, found) => {
    const reverseListUSD = [
      "AUS200",
      "ESP35",
      "EUSTX50",
      "FRA40",
      "GER30",
      "UK100",
    ];
    const reverseListAUD = ["ESP35", "EUSTX50", "FRA40", "GER30", "UK100"];
    const reverseListGBP = ["ESP35", "EUSTX50", "FRA40", "GER30"];

    let buildPair;

    if (found.baseCurrency === depositCurrency) {
      setHide(true);
    } else {
      setHide(false);
    }

    setExchangeLabel(depositCurrency + "/" + found.baseCurrency);

    if (depositCurrency === "USD") {
      if (found.baseCurrency === "USD") {
        setExchangeRate(1);
      } else {
        if (reverseListUSD.find((p) => p === pair)) {
          buildPair = found.baseCurrency + "_" + depositCurrency;
          const { candles } = await fx.candles({ id: buildPair });

          let reversed = 1 / Number(candles[candles.length - 1].mid.c);
          setExchangeRate(reversed.toFixed(5));
        } else {
          buildPair = depositCurrency + "_" + found.baseCurrency;
          const { candles } = await fx.candles({ id: buildPair });
          setExchangeRate(Number(candles[candles.length - 1].mid.c));
        }
      }
    }
    if (depositCurrency === "AUD") {
      if (found.baseCurrency === "AUD") {
        setExchangeRate(1);
      } else {
        if (found.baseCurrency === "HKD") {
          const result = await runExternalAPICall("AUD");
          setExchangeRate(result.HKD);
        } else {
          if (reverseListAUD.find((p) => p === pair)) {
            buildPair = found.baseCurrency + "_" + depositCurrency;
            const { candles } = await fx.candles({ id: buildPair });
            let reversed = 1 / Number(candles[candles.length - 1].mid.c);
            setExchangeRate(reversed.toFixed(5));
          } else {
            buildPair = depositCurrency + "_" + found.baseCurrency;
            const { candles } = await fx.candles({ id: buildPair });
            setExchangeRate(Number(candles[candles.length - 1].mid.c));
          }
        }
      }
    }
    if (depositCurrency === "GBP") {
      if (found.baseCurrency === "GBP") {
        setExchangeRate(1);
      } else {
        if (found.baseCurrency === "HKD") {
          const result = await runExternalAPICall("GBP");
          setExchangeRate(result.HKD);
        } else {
          if (reverseListGBP.find((p) => p === pair)) {
            buildPair = found.baseCurrency + "_" + depositCurrency;
            const { candles } = await fx.candles({ id: buildPair });
            let reversed = 1 / Number(candles[candles.length - 1].mid.c);
            setExchangeRate(reversed.toFixed(5));
          } else {
            buildPair = depositCurrency + "_" + found.baseCurrency;
            const { candles } = await fx.candles({ id: buildPair });
            setExchangeRate(Number(candles[candles.length - 1].mid.c));
          }
        }
      }
    }
  };
  const runCommoditiesCals = async (pair, found) => {
    if (depositCurrency === "USD") {
      setHide(true);
    } else {
      setHide(false);
    }
    setExchangeLabel(`${depositCurrency}/USD`);

    if (depositCurrency === "USD") {
      setExchangeRate(1);
    } else {
      const { candles } = await fx.candles({
        id: `${depositCurrency}_USD`,
      });
      setExchangeRate(Number(candles[candles.length - 1].mid.c));
    }
  };
  const runForexCals = (pair, found) => {
    let buildPair;

    //us
    const reverseUSD = [
      "AUDNZD",
      "GBPAUD",
      "GBPNZD",
      "EURAUD",
      "EURGBP",
      "EURNZD",
    ];

    // aud
    const reverseAUD = ["GBPAUD", "EURAUD", "EURGBP"];

    // gbp
    const reverseGBP = ["EURGBP"];

    const runExchangeApi = async (reversed, buildPair) => {
      const { candles } = await fx.candles({ id: buildPair });
      if (reversed) {
        let reversed = 1 / Number(candles[candles.length - 1].mid.c);
        setExchangeRate(reversed.toFixed(5));
      } else {
        setExchangeRate(Number(candles[candles.length - 1].mid.c));
      }
    };

    setExchangeLabel(depositCurrency + "/" + found.oanda.split("_")[1]);

    if (found.oanda.split("_")[1] === depositCurrency) {
      setHide(true);
    } else {
      setHide(false);
    }

    if (found.oanda.split("_")[1] === "USD") {
      setExchangeRate(1);
    } else {
      if (depositCurrency === "USD") {
        if (reverseUSD.find((p) => p === pair)) {
          buildPair = found.oanda.split("_")[1] + "_" + depositCurrency;
          runExchangeApi(true, buildPair);
        } else {
          buildPair = depositCurrency + "_" + found.oanda.split("_")[1];
          runExchangeApi(false, buildPair);
        }
      } else if (depositCurrency === "AUD") {
        if (reverseAUD.find((p) => p === pair)) {
          buildPair = found.oanda.split("_")[0] + "_" + depositCurrency;
          runExchangeApi(true, buildPair);
        } else {
          buildPair = depositCurrency + "_" + found.oanda.split("_")[1];
          runExchangeApi(false, buildPair);
        }
      } else if (depositCurrency === "GBP") {
        if (reverseGBP.find((p) => p === pair)) {
          buildPair = found.oanda.split("_")[0] + "_" + depositCurrency;
          runExchangeApi(true, buildPair);
        } else {
          buildPair = depositCurrency + "_" + found.oanda.split("_")[1];
          runExchangeApi(false, buildPair);
        }
      }
    }
  };
  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const pipValueCal = () => {
    const found = markets.find((p) => p.ticker === pair);
    let result = (found.pipDistance / exchangeRate) * contractSize;

    return result;
  };
  const moneySizeCalculator = () => {
    let result = accountBalance * (risk / 100);
    return result;
  };
  const lotSizeCalculator = (moneyRisk) => {
    let pipValue = pipValueCal();

    let result = moneyRisk / (stopLossPips * pipValue);

    return result;
  };
  const runCals = () => {
    if (stopLossPips === 0) {
      throwMessage("warning", "Enter Stop Loss");
    } else {
      const _moneyRisk = moneySizeCalculator();

      const _lots = lotSizeCalculator(_moneyRisk);

      setMoneyRisk(_moneyRisk.toFixed(2));
      setLots(_lots.toFixed(3));

      let units = _lots * contractSize;
      setUnits(numberWithCommas(units.toFixed(3)));
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "100%",
        height: "100%",
        outline: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "&::WebkitScrollbar": { width: 0 },
        p: 1,
      }}
    >
      <Grid
        gap={2}
        container
        direction="row"
        sx={{
          "&::WebkitScrollbar": { width: 0 },
          maxWidth: width <= 800 ? "100% !important" : "50% !important",
          backgroundColor: theme[0] === "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
          border:
            theme[0] === "dark"
              ? "1px solid #FCFCFF"
              : "1px solid rgba(37,37,37)",

          outline: "none",
          p: 4,
          borderRadius: "10px",
          overflowY: "scroll !important",
          height: "100%",
          margin: "auto",
          maxHeight: width < 390 ? "605px" : "730px",
        }}
      >
        <Grid
          container
          gap={4}
          justifyContent="center"
          alignItems="center"
          sx={{
            overflowY: "scroll !important",
            overflowX: "hidden !important",
          }}
        >
          <Grid item xs={12}>
            <h3
              style={{
                textAlign: "center",
                fontWeight: "300",
                fontSize: "23px",
                color: theme[0] === "dark" ? "#FCFCFF" : "rgba(37,37,37)",
                borderBottom:
                  theme[0] === "dark"
                    ? " 1px solid #FCFCFF"
                    : "1px solid rgba(37,37,37)",
                maxWidth: "60%",
                margin: "1em auto 1em auto",
                padding: "0 0 10px 0",
                cursor: "pointer",
              }}
            >
              Position Size Calculator
            </h3>
          </Grid>
          <Grid item xs={width > 500 ? 5 : 11}>
            <FormControl fullWidth size={"small"}>
              <InputLabel
                sx={{
                  color:
                    theme[0] === "dark"
                      ? "#FCFCFF !important"
                      : "rgba(37,37,37) !important",
                  borderOutline:
                    theme[0] === "dark"
                      ? "#FCFCFF !important"
                      : "rgba(37,37,37) !important",
                }}
              >
                Instrument
              </InputLabel>

              <Select
                MenuProps={{
                  PaperProps: {
                    sx: {
                      bgcolor:
                        theme[0] === "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
                      maxHeight: "450px",

                      "& .MuiMenuItem-root": {
                        padding: 1.5,
                        justifyContent: "center",
                      },
                    },
                  },
                }}
                defaultValue=""
                fullWidth
                sx={{
                  ".MuiSvgIcon-root": {
                    fill: theme[0] === "light" ? "rgba(40, 42, 46)" : "#FCFCFF",
                  },
                  color: theme[0] === "dark" ? "#FCFCFF" : "black",

                  "& fieldset": {
                    // - The <fieldset> inside the Input-root

                    borderColor:
                      theme[0] === "dark" ? "#FCFCFF" : "rgba(40, 42, 46)", // - Set the Input border
                  },
                  "&:hover fieldset": {
                    borderColor:
                      theme[0] === "dark" ? "#FCFCFF" : "rgba(40, 42, 46)", // - Set the Input border when parent has :hover
                  },
                  "&.Mui-focused fieldset": {
                    // - Set the Input border when parent is focused
                    borderColor:
                      theme[0] === "dark" ? "#FCFCFF" : "rgba(40, 42, 46)",
                  },

                  input: {
                    margin: "0",
                    color: theme[0] === "dark" ? "#FCFCFF" : "black",
                  },
                }}
                value={pair}
                label="Instrument"
                onChange={(event) => {
                  setPair(event.target.value);
                  clearFields();
                  runOandaFunc(event.target.value);
                }}
              >
                {markets.map((m) => {
                  if (m.label === "Forex") {
                    return (
                      <ListSubheader
                        key={m.label}
                        sx={{
                          textAlign: "center",
                          bgcolor:
                            theme[0] === "dark"
                              ? "rgba(40, 42, 46)"
                              : "#FCFCFF",
                          color: "#ED9E0E",
                          fontSize: "0.6em",
                        }}
                      >
                        Forex
                      </ListSubheader>
                    );
                  } else if (m.label === "Indices") {
                    return (
                      <ListSubheader
                        key={m.label}
                        sx={{
                          textAlign: "center",
                          bgcolor:
                            theme[0] === "dark"
                              ? "rgba(40, 42, 46)"
                              : "#FCFCFF",
                          color: "#ED9E0E",
                          fontSize: "0.6em",
                        }}
                      >
                        Indices
                      </ListSubheader>
                    );
                  } else if (m.label === "Commodities") {
                    return (
                      <ListSubheader
                        key={m.label}
                        sx={{
                          textAlign: "center",
                          bgcolor:
                            theme[0] === "dark"
                              ? "rgba(40, 42, 46)"
                              : "#FCFCFF",
                          color: "#ED9E0E",
                          fontSize: "0.6em",
                        }}
                      >
                        Commodities
                      </ListSubheader>
                    );
                  } else if (m.label === "Crypto") {
                    return (
                      <ListSubheader
                        key={m.label}
                        sx={{
                          textAlign: "center",
                          bgcolor:
                            theme[0] === "dark"
                              ? "rgba(40, 42, 46)"
                              : "#FCFCFF",
                          color: "#ED9E0E",
                          fontSize: "0.6em",
                        }}
                      >
                        Crypto
                      </ListSubheader>
                    );
                  } else {
                    return (
                      <MenuItem key={m.label} sx={style} value={m.ticker}>
                        {m.label}
                      </MenuItem>
                    );
                  }
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={width > 500 ? 5 : 11}>
            <CustomSelect
              size="small"
              inputLabel="Deposit Currency"
              value={depositCurrency}
              label="Deposit Currency"
              onChange={(e) => {
                clearFields();
                setExchangeRate(0);
                setDepositCurrency(e.target.value);
              }}
              menuItemArray={JSON.stringify([
                { value: "USD", label: "USD" },
                { value: "AUD", label: "AUD" },
                { value: "GBP", label: "GBP" },
              ])}
            />
          </Grid>
          <Grid item xs={width > 500 ? 5 : 11}>
            <CustomSingleTextField
              type="number"
              size="small"
              fullWidth
              label="Stop Loss"
              value={stopLossPips}
              onChange={(event) => {
                clearFields();
                setStopLossPips(event.target.value);
              }}
            />
          </Grid>
          <Grid item xs={width > 500 ? 5 : 11}>
            <CustomSingleTextField
              type="number"
              size="small"
              fullWidth
              label="Account Balance"
              value={accountBalance}
              onChange={(event) => {
                clearFields();
                setAccountBalance(event.target.value);
              }}
            />
          </Grid>
          <Grid item xs={width > 500 ? 5 : 11}>
            <CustomSingleTextField
              type="number"
              size="small"
              fullWidth
              label="Risk %"
              value={risk}
              onChange={(event) => {
                clearFields();
                setRisk(event.target.value);
              }}
            />
          </Grid>

          {customContractSize ? (
            <Grid item xs={width > 500 ? 4 : 11}>
              <CustomSingleTextField
                onBlur={() => {
                  if (!contractSize) {
                    setCustomContractSize(false);
                  }
                }}
                type="number"
                size="small"
                fullWidth
                label={"Contract Size"}
                value={contractSize}
                onChange={(e) => {
                  clearFields();
                  setContractSize(e.target.value);
                }}
              />
            </Grid>
          ) : (
            <Grid item xs={width > 500 ? 5 : 11}>
              <CustomSelect
                size="small"
                inputLabel="Contract Size"
                value={contractSize}
                label="Contract Size"
                onChange={(e) => {
                  clearFields();
                  setContractSize(e.target.value);
                  if (e.target.value === 0) {
                    setCustomContractSize(true);
                  }
                }}
                menuItemArray={JSON.stringify([
                  { value: 100000, label: "Standard - 100,000" },
                  { value: 10000, label: "Mini - 10,000" },
                  { value: 1000, label: "Micro - 1000" },
                  { value: 100, label: "Nano - 100" },
                  { value: 0, label: "Custom" },
                ])}
              />
            </Grid>
          )}
          {!hide && (
            <Grid item xs={width > 500 ? 5 : 11}>
              <CustomSingleTextField
                type="number"
                size="small"
                fullWidth
                label={exchangeLabel ? exchangeLabel : "Exchange Rate"}
                value={exchangeRate}
                onChange={(e) => {
                  clearFields();
                  setExchangeRate(e.target.value);
                }}
              />
            </Grid>
          )}

          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "5px auto -0.9em auto",
            }}
          >
            <CustomButtom
              variant="outlined"
              label="Calculate"
              onClick={() => runCals()}
            />
            <Tooltip title="Close">
              <CloseIcon
                fontSize="large"
                sx={{
                  color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
                  cursor: "pointer",
                  textAlign: "center",
                  margin: "0 20px",
                }}
                onClick={(e) => onClose(e)}
              />
            </Tooltip>
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "space-around",
              alignItem: "center",
            }}
          >
            <p
              style={{
                color: theme[0] === "dark" ? "#FCFCFF" : "rgba(37,37,37)",
                textAlign: "center",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-around",
                alignItem: "center",
                margin: "10px 10px 20px 10px",
                maxWidth: "500px",
                // minWidth: "375px",
              }}
            >
              <span style={{ margin: "0 10px" }}>
                <span style={{ fontSize: "20px", fontWeight: "300" }}>
                  Lots:{" "}
                </span>
                <span
                  style={{
                    fontSize: "18px",
                    fontWeight: "300",
                    color: "#ED9E0E",
                  }}
                >
                  {lots}
                </span>
              </span>
              <span style={{ margin: "0 10px" }}>
                <span style={{ fontSize: "20px", fontWeight: "300" }}>
                  Units:{" "}
                </span>
                <span
                  style={{
                    fontSize: "18px",
                    fontWeight: "300",
                    color: "#ED9E0E",
                  }}
                >
                  {units}
                </span>
              </span>
              <span style={{ margin: "0 10px" }}>
                <span style={{ fontSize: "20px", fontWeight: "300" }}>
                  Money:{" "}
                </span>
                <span
                  style={{
                    fontSize: "18px",
                    fontWeight: "300",
                    color: "#ED9E0E",
                  }}
                >
                  {depositCurrency === "gbp" ? moneySymbol[1] : moneySymbol[0]}
                  {moneyRisk}
                </span>
              </span>
            </p>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default Cal;

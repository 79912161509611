import { useState, useEffect, useContext, useRef } from "react";

import NewReflection from "../../components/modals/new-reflection";
import NewForecast from "../../components/modals/new-forecast";
import SingleForecast from "./single-forecasts";
import SingleReflection from "./single-reflections";
import AuthContext from "../../lib/auth-context";
import ThemeContext from "../../lib/theme-context";
import { DataFetcher } from "../../utils/dataFetcher";
import moment from "moment";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";

import interactionPlugin from "@fullcalendar/interaction";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

import { animateScroll } from "react-scroll";

import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import Journal from "../../components/modals/journal";
import useWindowDimensions from "../../utils/width";

const Calender = () => {
  const { width } = useWindowDimensions();
  const auth = useContext(AuthContext);
  const theme = useContext(ThemeContext);
  const effectRan = useRef(false);
  const [openForecast, setOpenForecast] = useState(false);
  const [openReflection, setOpenReflection] = useState(false);
  const [singleForecastView, setSingleForecastView] = useState();
  const [singleReflectionView, setSingleReflectionView] = useState();
  const [singleJournalView, setSingleJournalView] = useState();
  const [eventMoveBool, setEventMoveBool] = useState(false);
  const [openJournal, setOpenJournal] = useState(false);
  // const [touchDevice, setTouchDevice] = useState(false);

  const calendarRef = useRef(null);

  const pickColorForPair = (pair) => {
    if (pair.startsWith("AUD")) {
      return "#287271";
    } else if (pair.startsWith("GBP")) {
      return "#6e78ff";
    } else if (pair.startsWith("NZD")) {
      return "#ff87ab";
    } else if (pair.startsWith("CAD")) {
      return "#8ab17d";
    } else if (pair.startsWith("CHF")) {
      return "#e9c46a";
    } else if (pair.startsWith("EUR")) {
      return "#f4a261";
    } else if (pair.startsWith("USD")) {
      return "#e76f51";
    }
  };

  const checkIfForecastIsNotAssignedToABacktest = () => {
    const forecast_Ids = auth.forecastsBacktesting.map((f) => {
      return f._id;
    });

    let trades = auth.trades.filter((trades) =>
      trades.id?.includes("backtest-")
    );

    trades = trades.map((t) => t.foreCastId);

    const forecastIdsThatDontHaveTradesAssigned = forecast_Ids.filter(
      (b) => !trades.some((a) => a === b)
    );

    const matchingForecastIds = auth.forecastsBacktesting.filter((l) => {
      return forecastIdsThatDontHaveTradesAssigned.some((term) =>
        l._id.includes(term)
      );
    });

    return matchingForecastIds;
  };

  const checkfForecastsToHide = () => {
    const array = auth.trades.filter((trades) =>
      trades.id?.includes("backtest-")
    );

    const backtests = auth.backtests
      .filter((tests) => tests.archived === "false")
      .map((t) => t.testId);

    const matchingForecastIds = array.filter((l) => {
      return backtests.some((term) => l.testId.includes(term));
    });

    const foundIds = matchingForecastIds?.map((i) => i.foreCastId);

    const matching = auth.forecastsBacktesting.filter((l) => {
      return foundIds.some((term) => l._id.includes(term));
    });

    // concat matching with no linked trade forecast here

    const joinArray =
      checkIfForecastIsNotAssignedToABacktest().concat(matching);

    return joinArray.map((f) => {
      return {
        title: `${f.pair}  Forecast`,
        date: moment(f.date).format("YYYY-MM-DD"),
        color: pickColorForPair(f.pair),
        publicId: f._id,
      };
    });
  };

  const checkfForecastsToHideFilter = () => {
    const array = auth.trades.filter((trades) =>
      trades.id?.includes("backtest-")
    );

    const backtests = auth.backtests
      .filter((tests) => tests.archived === "false")
      .map((t) => t.testId);

    const matchingForecastIds = array.filter((l) => {
      return backtests.some((term) => l.testId.includes(term));
    });

    const foundIds = matchingForecastIds?.map((i) => i.foreCastId);

    const matching = auth.forecastsBacktesting.filter((l) => {
      return foundIds.some((term) => l._id.includes(term));
    });

    // concat matching with no linked trade forecast here

    const joinArray =
      checkIfForecastIsNotAssignedToABacktest().concat(matching);

    return joinArray
      .filter((forecast) => forecast.active.includes("true"))
      .map((f) => {
        return {
          title: `${f.pair}  Forecast`,
          date: moment(f.date).format("YYYY-MM-DD"),
          color: pickColorForPair(f.pair),
          publicId: f._id,
        };
      });
  };

  const allForecastsBacktestingModeChecked = checkfForecastsToHide();

  const allForecastsBacktestingActiveModeChecked =
    checkfForecastsToHideFilter();

  const allForecastsActiveModeChecked = auth.forecasts
    .filter((forecast) => forecast.active.includes("true"))
    .map((f) => {
      return {
        title: `${f.pair} ${f.direction[0]} Forecast`,
        date: moment(f.date).format("YYYY-MM-DD"),
        color: pickColorForPair(f.pair),
        publicId: f._id,
      };
    });
  const allForecastsModeChecked = auth.forecasts.map((f) => {
    return {
      title: `${f.pair} ${f.direction[0]} Forecast`,
      date: moment(f.date).format("YYYY-MM-DD"),
      color: pickColorForPair(f.pair),
      publicId: f._id,
    };
  });

  const allTradesModeChecked = () => {
    const array = auth.trades
      .filter((trades) => trades.missedTrade?.includes("No"))
      .filter((trades) =>
        trades.id?.includes(auth.backtestingMode ? "backtest-" : "live-")
      );
    const backtests = auth.backtests
      .filter((tests) => tests.archived === "false")
      .map((t) => t.testId);

    const matchingTrades = array.filter((l) => {
      return backtests.some((term) => l.testId.includes(term));
    });

    if (auth.backtestingMode) {
      return matchingTrades.map((f) => {
        return {
          title: `${f.pair} ${f.direction}`,
          date: moment(f.created).format("YYYY-MM-DD"),
          color: f.direction === "Long" ? "#00A36C" : "#DC143C",
          publicId: f._id,
        };
      });
    } else {
      return array.map((f) => {
        return {
          title: `${f.pair} ${f.direction}`,
          date: moment(f.created).format("YYYY-MM-DD"),
          color: f.direction === "Long" ? "#00A36C" : "#DC143C",
          publicId: f._id,
        };
      });
    }
  };

  const missedTradesModeChecked = auth?.trades
    .filter((trades) => trades.missedTrade?.includes("Yes"))
    ?.map((f) => {
      return {
        title: `(M) ${f.pair} ${f.direction}`,
        date: moment(f.created).format("YYYY-MM-DD"),
        color: f.direction === "Long" ? "#00A36C" : "#DC143C",
        publicId: f._id,
      };
    });

  const allReflectionsModeChecked = auth.reflections.map((f) => {
    return {
      title: `${f.typeOfReview} Reflection`,
      date: moment(f.date).format("YYYY-MM-DD"),
      color:
        f.typeOfReview === "Weekly"
          ? "#ED9E0E"
          : f.typeOfReview === "Monthly"
          ? "#94c936"
          : f.typeOfReview === "Annual"
          ? "#9F2B68"
          : f.typeOfReview?.includes("Q")
          ? "#779ECB"
          : null,
      publicId: f._id,
    };
  });

  const forecasts =
    auth.backtestingMode && auth.defaultValue === "Active Forecast"
      ? allForecastsBacktestingActiveModeChecked
      : auth.backtestingMode && auth.defaultValue === "All Forecast"
      ? allForecastsBacktestingModeChecked
      : !auth.backtestingMode && auth.defaultValue === "Active Forecast"
      ? allForecastsActiveModeChecked
      : !auth.backtestingMode && auth.defaultValue === "All Forecast"
      ? allForecastsModeChecked
      : auth.defaultValue === "All" && !auth.backtestingMode
      ? allForecastsModeChecked
      : auth.defaultValue === "All" && auth.backtestingMode
      ? allForecastsBacktestingModeChecked
      : [];

  const handleOpenNewReflection = () => {
    setOpenReflection(true);
  };
  const handleOpenNewForecast = () => setOpenForecast(true);
  const handleCloseNewForecast = () => setOpenForecast(false);
  const handleCloseNewReflection = () => setOpenReflection(false);

  const handleCloseJournal = () => {
    localStorage.removeItem("journal-portal");
    localStorage.removeItem("trade-portal");
    setOpenJournal(false);
    setSingleReflectionView(true);
  };

  const scrollToBottom = () => {
    const options = {
      duration: 500,
      smooth: true,
    };

    animateScroll.scrollToBottom(options);
  };
  const handleScroll = () => {
    if (moment().format("D") >= 16) {
      scrollToBottom();
    }
  };

  useEffect(() => {
    if (effectRan.current === false) {
      // handleScroll();
      if (localStorage.getItem("forecast-to-view")) {
        setSingleForecastView(true);
      }
      if (localStorage.getItem("reflection-to-view")) {
        setSingleReflectionView(true);
      }
      if (!auth.backtestingMode && auth.defaultValue === "Missed Trades") {
        auth.setDefaultValue("All");
      }

      return () => {
        effectRan.current = true;
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //[localStorage.getItem("forecast-to-view"),localStorage.getItem("reflection-to-view")]);
  const eventMove = async (e) => {
    const newDate = e.event._instance.range.start;
    const id = e.event.extendedProps.publicId;
    const title = e.event._def.title;
    if (title.includes("Short")) {
      const filteredArrayTrades = auth.trades.filter((x) => x._id === id);
      auth.setTrades([...auth.trades, filteredArrayTrades]);
      auth.throwMessage("info", "Not Allowed");
    } else if (title.includes("Long")) {
      const filteredArrayTrades = auth.trades.filter((x) => x._id === id);
      auth.setTrades([...auth.trades, filteredArrayTrades]);
      auth.throwMessage("info", "Not Allowed");
    } else if (title.includes("Reflection")) {
      const result = await DataFetcher("edit-reflection", {
        _id: id,
        date: moment(newDate),
      });
      setEventMoveBool(true);
      if (result) {
        const filteredArray = auth.reflections.filter((x) => x._id !== id);
        filteredArray.push(result);
        auth.setReflections(filteredArray);
        setTimeout(() => {
          setEventMoveBool(false);
        }, 600);
      }
    } else if (title.includes("Forecast")) {
      const result = await DataFetcher("edit-forecast", {
        _id: id,
        date: moment(newDate),
      });
      if (result) {
        if (auth.backtestingMode) {
          const filteredArray = auth.forecastsBacktesting.filter(
            (x) => x._id !== id
          );
          filteredArray.push(result);
          auth.setForecastsBacktesting(filteredArray);
        } else {
          const filteredArray = auth.forecasts.filter((x) => x._id !== id);
          filteredArray.push(result);
          auth.setForecasts(filteredArray);
        }
      }
    }
  };
  const speed = () => {
    if (auth.backtestingMode) {
      return [
        {
          icon: <QueryStatsIcon onClick={handleOpenNewForecast} />,
          name: "Forecast",
        },
      ];
    } else {
      return [
        {
          icon: <QueryStatsIcon onClick={handleOpenNewForecast} />,
          name: "Forecast",
        },
        {
          icon: <AutoStoriesIcon onClick={handleOpenNewReflection} />,
          name: "Reflect",
        },
      ];
    }
  };

  const goDoDate = (date) => {
    try {
      const calendarApi = calendarRef.current?.getApi();
      calendarApi.gotoDate(date);
    } catch {
      return;
    }
  };
  const getDate = () => {
    try {
      const calendarApi = calendarRef.current?.getApi();
      return calendarApi.getDate();
    } catch {
      return;
    }
  };

  useEffect(() => {
    const calendarApi = calendarRef.current?.getApi();
    width >= 790
      ? calendarApi.changeView("dayGridMonth")
      : calendarApi.changeView("listWeek");
  }, [width]);

  useEffect(() => {
    const date = localStorage.getItem("view-date");

    if (!date) {
      goDoDate(new Date());
    } else {
      goDoDate(new Date(date));
    }
  }, [singleForecastView, singleReflectionView]);

  return singleForecastView ? (
    <SingleForecast
      setSingleForecastView={setSingleForecastView}
      handleScroll={handleScroll}
      setSingleReflectionView={setSingleReflectionView}
    />
  ) : singleReflectionView ? (
    <SingleReflection
      setSingleReflectionView={setSingleReflectionView}
      handleScroll={handleScroll}
      setSingleJournalView={setSingleJournalView}
      setOpenJournal={setOpenJournal}
      setSingleForecastView={setSingleForecastView}
      handleCloseNewReflection={handleCloseNewReflection}
    />
  ) : singleJournalView ? (
    <Journal
      open={openJournal}
      onClose={handleCloseJournal}
      handleOpenNewReflection={handleOpenNewReflection}
      setSingleReflectionView={setSingleReflectionView}
      setSingleJournalView={setSingleJournalView}
      setOpenJournal={setOpenJournal}
      calculateFields={false}
    />
  ) : (
    <div
      style={{
        color: theme[0] !== "dark" ? "rgba(37,37,37)" : "#FCFCFF",
        padding: width < 500 ? "0" : "0 1em",
        height: "auto",
        width: "100%",
        overflowY: 'hidden',
        margin: "0 auto 0 auto",
        backgroundColor: theme[0] === "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
      }}
    >
      <div>
        <NewForecast open={openForecast} onClose={handleCloseNewForecast} />
        <NewReflection
          open={openReflection}
          onClose={handleCloseNewReflection}
          openReflection={openReflection}
          setSingleJournalView={setSingleJournalView}
          setOpenJournal={setOpenJournal}
          setSingleReflectionView={setSingleReflectionView}
          setSingleForecastView={setSingleForecastView}
        />

        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "0.8em",
          }}
        >
          <FormControl
            sx={{
              position: width >= 899 ? "absolute" : "static",
              top: width >= 899 ? "4.1em" : 0,
              marginLeft: width >= 899 ? "-50px" : 0,
            }}
          >
            <RadioGroup
              row
              onChange={(e) => {
                switch (e.target.value) {
                  case "All":
                    auth.setDefaultValue("All");
                    localStorage.setItem("calendar-view-mode", "All");
                    break;
                  case "Reflections":
                    auth.setDefaultValue("Reflections");

                    localStorage.setItem("calendar-view-mode", "Reflections");
                    break;

                  case "Trades":
                    auth.setDefaultValue("Trades");
                    localStorage.setItem("calendar-view-mode", "Trades");
                    break;

                  case "Missed Trades":
                    auth.setDefaultValue("Missed Trades");
                    localStorage.setItem("calendar-view-mode", "Missed Trades");
                    break;

                  case "Active Forecasts":
                    auth.setDefaultValue("Active Forecasts");
                    localStorage.setItem(
                      "calendar-view-mode",
                      "Active Forecasts"
                    );
                    break;
                  case "All Forecasts":
                    auth.setDefaultValue("All Forecasts");
                    localStorage.setItem("calendar-view-mode", "All Forecasts");
                    break;

                  default:
                    break;
                }
              }}
              value={auth.defaultValue ? auth.defaultValue : ""}
              defaultValue={auth.defaultValue}
              sx={{
                fontWeight: "800",
                color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
              }}
            >
              {auth.backtestingMode && (
                <FormControlLabel
                  value="Missed Trades"
                  control={
                    <Radio
                      sx={{
                        color:
                          theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
                      }}
                    />
                  }
                  label="Missed"
                />
              )}

              <FormControlLabel
                value="Active Forecasts"
                control={
                  <Radio
                    sx={{
                      color:
                        theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
                    }}
                  />
                }
                label="Focus"
              />
              <FormControlLabel
                value="Trades"
                control={
                  <Radio
                    sx={{
                      color:
                        theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
                    }}
                  />
                }
                label="Trades"
              />
              {!auth.backtestingMode && (
                <FormControlLabel
                  value="Reflections"
                  control={
                    <Radio
                      sx={{
                        color:
                          theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
                      }}
                    />
                  }
                  label="Reflections"
                />
              )}

              <FormControlLabel
                value="All Forecasts"
                control={
                  <Radio
                    sx={{
                      color:
                        theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
                    }}
                  />
                }
                label="Forecasts"
              />
              <FormControlLabel
                value="All"
                control={
                  <Radio
                    sx={{
                      color:
                        theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
                    }}
                  />
                }
                label="All"
              />
            </RadioGroup>
          </FormControl>
          <SpeedDial
            // onTouchStart={() => setTouchDevice(true)}
            FabProps={{
              sx: {
                bgcolor: "#3788d8",
              },
            }}
            ariaLabel="Menu"
            sx={{ position: "fixed", bottom: 40, right: 50 }}
            icon={<SpeedDialIcon />}
          >
            {speed().map((action) => (
              <SpeedDialAction
                FabProps={{
                  sx: {
                    color: theme[0] === "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
                    backgroundColor:
                      theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
                  },
                }}
                tooltipOpen={true}
                // tooltipOpen={touchDevice ? true : false}
                key={action.name}
                icon={action.icon}
                tooltipTitle={action.name}
              />
            ))}
          </SpeedDial>

          {/* {!auth.backtestingMode ? (
          <CustomButtom
            variant="outlined"
            label="Reflect"
            onClick={handleOpenNewReflection}
          />
        ) : (
          <div style={{ width: "90px" }}></div>
        )} */}
        </div>

        <FullCalendar
          ref={calendarRef}
          initialView={width >= 700 ? "dayGridMonth" : "listWeek"}
          plugins={[dayGridPlugin, listPlugin, interactionPlugin]}
          events={
            auth.defaultValue === "All"
              ? [
                  ...forecasts,
                  ...allTradesModeChecked(),
                  ...(auth.backtestingMode ? missedTradesModeChecked : []),
                  ...(!auth.backtestingMode ? allReflectionsModeChecked : []),
                ]
              : auth.defaultValue === "Active Forecasts"
              ? auth.backtestingMode
                ? allForecastsBacktestingActiveModeChecked
                : allForecastsActiveModeChecked
              : auth.defaultValue === "All Forecasts"
              ? auth.backtestingMode
                ? allForecastsBacktestingModeChecked
                : allForecastsModeChecked
              : auth.defaultValue === "Trades"
              ? allTradesModeChecked()
              : auth.defaultValue === "Missed Trades"
              ? missedTradesModeChecked
              : auth.defaultValue === "Reflections"
              ? allReflectionsModeChecked
              : []
          }
          dayMaxEvents={true}
          editable={true}
          eventClick={(e) => {
            if (e.event._def.title.includes("Forecast")) {
              setSingleForecastView(true);
              const FCD = getDate();
              localStorage.setItem("view-date", FCD);
              localStorage.setItem(
                "forecast-to-view",
                e.event._def.extendedProps.publicId
              );
            } else if (
              e.event._def.title.includes("Weekly") ||
              e.event._def.title.includes("Monthly") ||
              e.event._def.title.includes("Annual") ||
              e.event._def.title.includes("Q")
            ) {
              if (!eventMoveBool) {
                const FCD = getDate();
                localStorage.setItem("view-date", FCD);
                setSingleReflectionView(true);
                localStorage.setItem(
                  "reflection-to-view",
                  e.event._def.extendedProps.publicId
                );
              }
            } else {
              const FCD = getDate();
              localStorage.setItem("view-date", FCD);
              auth.setTab(1);
              localStorage.setItem(
                "trade-to-view",
                e.event._def.extendedProps.publicId
              );
            }
          }}
          eventDrop={(e) => eventMove(e)}
        />
      </div>
    </div>
  );
};

export default Calender;

// const allForecastsBacktestingModeChecked = auth?.forecastsBacktesting?.map(
//   (f) => {
//     return {
//       title: `${f.pair}  Forecast`,
//       date: moment(f.date).format("YYYY-MM-DD"),
//       color: pickColorForPair(f.pair),
//       publicId: f._id,
//     };
//   }
// );

// const allForecastsBacktestingActiveModeChecked = auth?.forecastsBacktesting
//   ?.filter((forecast) => forecast.active.includes("true"))
//   .map((f) => {
//     return {
//       title: `${f.pair}  Forecast`,
//       date: moment(f.date).format("YYYY-MM-DD"),
//       color: pickColorForPair(f.pair),
//       publicId: f._id,
//     };
//   });

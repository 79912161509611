import { React, useContext, useState, useEffect } from "react";
import ThemeContext from "../../lib/theme-context";
import AuthContext from "../../lib/auth-context";
import useWindowDimensions from "../../utils/width";
import Tooltip from "@mui/material/Tooltip";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";

const StatsBar = ({
  realisedPercentage,
  realisedPercentageBacktest,
  calculateFields,
  showBacktestTrades,
}) => {
  const theme = useContext(ThemeContext);
  const auth = useContext(AuthContext);
  const { width } = useWindowDimensions();
  const [run, setRun] = useState(false);
  const [capital, setCapital] = useState();
  const [headingText, setHeadingText] = useState();

  const startingBalance =
    localStorage.getItem("equity-mode") === "Personal Capital"
      ? JSON.parse(localStorage.getItem("user")).startingBalancePC
      : localStorage.getItem("equity-mode") === "Investor Capital"
      ? JSON.parse(localStorage.getItem("user")).startingBalanceIC
      : JSON.parse(localStorage.getItem("user")).startingBalanceOptions;

  const array = [
    localStorage.getItem("equity-mode"),
    localStorage.getItem("balanceIC"),
    localStorage.getItem("balancePC"),
  ];

  const setRotater = async () => {
    const storage = localStorage.getItem("rotater");
    if (storage === "Start Of Year") {
      calculateFields(
        auth.trades,
        auth.backtestingMode,
        localStorage.getItem("backtest-active"),
        "3 Months"
      );
      localStorage.setItem("rotater", "3 Months");
      // setStorage("3 Months");
      setTimeout(() => {
        // setStorage("");
        setRun(false);
      }, 500);
    } else if (storage === "3 Months") {
      calculateFields(
        auth.trades,
        auth.backtestingMode,
        localStorage.getItem("backtest-active"),
        "Monthly"
      );
      localStorage.setItem("rotater", "Monthly");
      // setStorage("Monthly");
      setTimeout(() => {
        // setStorage("");
        setRun(false);
      }, 500);
    } else if (storage === "Monthly") {
      calculateFields(
        auth.trades,
        auth.backtestingMode,
        localStorage.getItem("backtest-active"),
        "Weekly"
      );
      localStorage.setItem("rotater", "Weekly");
      // setStorage("Weekly");
      setTimeout(() => {
        // setStorage("");
        setRun(false);
      }, 500);
    } else if (storage === "Weekly") {
      calculateFields(
        auth.trades,
        auth.backtestingMode,
        localStorage.getItem("backtest-active"),
        "24 Hours"
      );
      localStorage.setItem("rotater", "24 Hours");
      // setStorage("24 Hours");
      setTimeout(() => {
        // setStorage("");
        setRun(false);
      }, 500);
    } else if (storage === "24 Hours") {
      calculateFields(
        auth.trades,
        auth.backtestingMode,
        localStorage.getItem("backtest-active"),
        "All"
      );
      localStorage.setItem("rotater", "All");
      // setStorage("All");
      setTimeout(() => {
        // setStorage("");
        setRun(false);
      }, 500);
    } else {
      calculateFields(
        auth.trades,
        auth.backtestingMode,
        localStorage.getItem("backtest-active"),
        "Start Of Year"
      );
      localStorage.setItem("rotater", "Start Of Year");
      // setStorage("Start Of Year");
      setTimeout(() => {
        // setStorage("");
        setRun(false);
      }, 500);
    }
  };

  const changeYear = () => {
    const year = localStorage.getItem("year");
    if (year === "0") {
      setHeadingText("2024");
      localStorage.setItem("year", JSON.stringify(2024));
    } else if (year === "2024") {
      setHeadingText("2025");
      localStorage.setItem("year", JSON.stringify(2025));
    } else if (year === "2025") {
      setHeadingText("0");
      localStorage.setItem("year", JSON.stringify(0));
    }
    if (auth.backtestingMode) {
      showBacktestTrades({
        startDate: JSON.parse(localStorage.getItem("user")).created,
        archived: "false",
        notes: "",
        pair: "All missed trades from",
        testId: "Missed Trades",
      });
    } else {
      calculateFields(auth.trades, auth.backtestingMode);
    }

    // add 2026 here
  };

  useEffect(() => {
    setHeadingText(
      localStorage.getItem("year") !== "0"
        ? localStorage.getItem("year")
        : "All"
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setCapital(
      localStorage.getItem("equity-mode") === "Personal Capital"
        ? auth.realisedBalancePC
        : localStorage.getItem("equity-mode") === "Investor Capital"
        ? auth.realisedBalanceIC
        : null
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, array);

  return (
    <div
      style={{
        cursor: "pointer",
        marginRight:
          auth.backtestingMode && width > 1049
            ? "18%"
            : !auth.backtestingMode && width > 1049
            ? "10%"
            : auth.backtestingMode && width < 1049
            ? "10%"
            : !auth.backtestingMode && width < 1049
            ? "11%"
            : "0%",
        position: width <= 500 ? "absolute" : null,
        top: "90px",
        left: "150px",
        // width: "150px",
      }}
    >
      <Stack direction="row" spacing={1}>
        {auth.backtestingMode && (
          <Tooltip title={localStorage.getItem("rotater")} placement="left">
            <Chip
              label={`${Math.abs(realisedPercentageBacktest)}%`}
              onClick={() => {
                if (!run) {
                  setRun(true);
                  setRotater();
                }
              }}
              clickable
              sx={{
                fontSize: "13.5px",
                border:
                  theme[0] === "dark"
                    ? " 1px solid #FCFCFF"
                    : "1px solid black",
                color:
                  realisedPercentageBacktest < 0
                    ? "#f23645"
                    : realisedPercentageBacktest > 0
                    ? "#26a65d"
                    : realisedPercentageBacktest === 0
                    ? "#ED9E0E"
                    : "#ED9E0E",
              }}
            />
          </Tooltip>
        )}

        {!auth.backtestingMode && (
          <Tooltip title={localStorage.getItem("rotater")} placement="left">
            <Chip
              label={`${Math.abs(realisedPercentage)}%`}
              onClick={() => {
                if (!run) {
                  setRun(true);
                  setRotater();
                }
              }}
              clickable
              sx={{
                fontSize: "13.5px",
                color:
                  realisedPercentage < 0
                    ? "#f23645"
                    : realisedPercentage > 0
                    ? "#26a65d"
                    : realisedPercentage === 0
                    ? "#ED9E0E"
                    : "#ED9E0E",
                border:
                  theme[0] === "dark"
                    ? " 1px solid #FCFCFF"
                    : "1px solid black",
              }}
            />
          </Tooltip>
        )}

        <Tooltip title={"Year"} placement="top">
          <Chip
            label={headingText !== "0" ? headingText : "All"}
            onClick={() => changeYear()}
            clickable
            sx={{
              fontSize: "13.5px",
              border:
                theme[0] === "dark" ? " 1px solid #FCFCFF" : "1px solid black",
              color: theme[0] === "dark" ? "#FCFCFF" : "black",
            }}
          />
        </Tooltip>

        {!auth.backtestingMode &&
          localStorage.getItem("equity-mode") !==
            "Personal & Investor Capital" && (
            <Tooltip title={"Balance"} placement="right">
              <Chip
                label={`$${Math.abs(capital)}`}
                clickable
                sx={{
                  cursor: "default",
                  fontSize: "13.5px",
                  border:
                    theme[0] === "dark"
                      ? " 1px solid #FCFCFF"
                      : "1px solid black",
                  color:
                    capital < Number(startingBalance)
                      ? "#f23645"
                      : capital > Number(startingBalance)
                      ? "#26a65d"
                      : capital === Number(startingBalance)
                      ? "#ED9E0E"
                      : "#ED9E0E",
                }}
              />
            </Tooltip>
          )}
      </Stack>
    </div>
  );
};

export default StatsBar;

import { React, useEffect, useState } from "react";
import AuthContext from "./lib/auth-context";
import { Routes, Route } from "react-router-dom";

import ThemeContext from "./lib/theme-context";

import LogIn from "./pages/log-in-screen";
import DashBoard from "./pages/dashboard";
import Loading from "./components/loading/loading.component";
import { ProtectedRoute } from "./lib/authWrapper";
import { mongoDBRequest } from "./services/api/dbRequest";
import { useNavigate } from "react-router-dom";
import { NotificationContainer } from "react-notifications";
import throwMessage from "./utils/throwMessage";
import "react-notifications/lib/notifications.css";

function isEmpty(obj) {
  for (const prop in obj) {
    if (Object.hasOwn(obj, prop)) {
      return false;
    }
  }

  return true;
}

const App = () => {
  const [theme, setTheme] = useState("dark");
  const [auth, setAuth] = useState(false);
  const navigate = useNavigate();
  const [forecasts, setForecasts] = useState([]);
  const [forecastsBacktesting, setForecastsBacktesting] = useState([]);
  const [trades, setTrades] = useState([]);
  const [allTrades, setAllTrades] = useState([]);
  const [reflections, setReflections] = useState([]);
  const [journals, setJournals] = useState([]);
  const [journalsBacktesting, setJournalsBacktesting] = useState([]);
  const [backtests, setBacktests] = useState([]);
  const [tab, setTab] = useState(undefined);
  const [backtestingMode, setBacktestingMode] = useState(false);
  const [viewMode, setViewMode] = useState("all-view");
  const [equityTypeMode, setEquityTypeMode] = useState("Personal Capital");
  const [realisedBalanceIC, setRealisedBalanceIC] = useState(
    localStorage.getItem("balanceIC")
  );
  const [realisedBalancePC, setRealisedBalancePC] = useState(
    localStorage.getItem("balancePC")
  );

  const [inProgressMode, setInProgressMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [defaultValue, setDefaultValue] = useState();
  const [status, setStatus] = useState(false);

  const handleLogIn = async (email, password) => {
    setLoading(true);
    return mongoDBRequest("sign-in", "POST", {
      email: email,
      password: password,
    })
      .then(async (data) => {
        if (data.TC_TOKEN_AUTH) {
          setAuth(true);
          localStorage.setItem("screen", 0);
          localStorage.setItem("theme", "dark");
          localStorage.setItem("calendar-view-mode", "All");
          localStorage.setItem("view-mode", "simple-view");
          localStorage.setItem("equity-mode", "Personal Capital");
          localStorage.setItem("TC_TOKEN_AUTH", data.TC_TOKEN_AUTH);
          localStorage.setItem("user", JSON.stringify(data.user));
          navigate("/");
          throwMessage("success", "Authenticated");
        } else {
          setAuth(false);
          if (data === "User with that email does not exist") {
            throwMessage("warning", data);
          } else if (data === "Email and password do not match") {
            throwMessage("warning", data);
          } else {
            throwMessage(
              "warning",
              "Opps Something went wrong please check the console"
            );
          }
        }
      })
      .catch((err) => {
        if (!isEmpty(err)) throwMessage("error", JSON.stringify(err));
      });
  };
  const handleBacktestingMode = (d) => {
    setEquityTypeMode("Personal Capital");
    localStorage.setItem("equity-mode", "Personal Capital");
    setBacktestingMode(d);
  };
  const authCheck = async () => {
    const TC_TOKEN_AUTH = localStorage.getItem("TC_TOKEN_AUTH");
    if (TC_TOKEN_AUTH && TC_TOKEN_AUTH.length === 149) {
      return await mongoDBRequest("verify", "POST", {
        TC_TOKEN_AUTH: TC_TOKEN_AUTH,
      })
        .then(async (data) => {
          if (data === "valid TC_TOKEN_AUTH") {
            setAuth(true);
            return true;
          } else {
            localStorage.removeItem("TC_TOKEN_AUTH");
            localStorage.removeItem("user");
            setAuth(false);
            return false;
          }
        })
        .catch((err) => {
          localStorage.removeItem("TC_TOKEN_AUTH");
          localStorage.removeItem("user");
          setAuth(false);
          if (!isEmpty(err)) throwMessage("error", JSON.stringify(err));
          return false;
        });
    } else {
      localStorage.removeItem("TC_TOKEN_AUTH");
      localStorage.removeItem("user");
      setAuth(false);
      return false;
    }
  };

  useEffect(() => {
  
    function changeStatus() {
      if (!navigator.onLine) {
        setStatus(false);
        localStorage.setItem("TC-status", false);
        throwMessage("error", "No Network Connection");
      } else if (navigator.onLine) {
        setStatus(true);
        localStorage.setItem("TC-status", true);
        throwMessage("success", "Network Connected");
      }
    }
    window.addEventListener("online", changeStatus);
    window.addEventListener("offline", changeStatus);
    setStatus(navigator.onLine);
    localStorage.setItem("TC-status", navigator.onLine);

  
    return () => {
      window.removeEventListener("online", changeStatus);
      window.removeEventListener("offline", changeStatus);
    };


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <NotificationContainer />
      <ThemeContext.Provider value={[theme, setTheme]}>
        <AuthContext.Provider
          value={{
            realisedBalanceIC: realisedBalanceIC,
            setRealisedBalanceIC: setRealisedBalanceIC,
            realisedBalancePC: realisedBalancePC,
            setRealisedBalancePC: setRealisedBalancePC,
            setBacktestingMode: setBacktestingMode,
            setTheme: setTheme,
            allTrades: allTrades,
            setAllTrades: setAllTrades,
            trades: trades,
            reflections: reflections,
            forecasts: forecasts,
            forecastsBacktesting: forecastsBacktesting,
            journals: journals,
            journalsBacktesting: journalsBacktesting,
            backtests: backtests,
            setTrades: setTrades,
            setBacktests: setBacktests,
            setReflections: setReflections,
            setForecasts: setForecasts,
            setForecastsBacktesting: setForecastsBacktesting,
            setJournals: setJournals,
            setJournalsBacktesting: setJournalsBacktesting,
            tab: tab,
            setTab: setTab,
            throwMessage: throwMessage,
            backtestingMode: backtestingMode,
            handleBacktestingMode: handleBacktestingMode,
            viewMode: viewMode,
            setViewMode: setViewMode,
            inProgressMode: inProgressMode,
            setInProgressMode: setInProgressMode,
            defaultValue: defaultValue,
            setDefaultValue: setDefaultValue,
            setLoading: setLoading,
            status: status,
            equityTypeMode: equityTypeMode,
            setEquityTypeMode: setEquityTypeMode,
          }}
        >
          <Routes>
            <Route
              path="/log-in"
              element={<LogIn handleLogIn={handleLogIn} />}
            />
            <Route
              path="/"
              element={
                <ProtectedRoute authCheck={authCheck}>
                  {!loading && auth ? (
                    <DashBoard />
                  ) : loading && auth ? (
                    <Loading />
                  ) : (
                    <Loading />
                  )}
                </ProtectedRoute>
              }
            />

            <Route
              path="*"
              element={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100vw",
                    height: "100vh",
                  }}
                >
                  <p style={{ color: "#FCFCFF" }}>Nothing Lives Here</p>
                </div>
              }
            />
          </Routes>
        </AuthContext.Provider>
      </ThemeContext.Provider>
    </>
  );
};

export default App;

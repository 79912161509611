import { React, useContext, useEffect, useState } from "react";
import { Wrapper } from "./dashboard.styles";

import Screens from "../../components/screens/screens.component";
import SettingsModal from "../../components/modals/settings";
import ThemeContext from "../../lib/theme-context";
import AuthContext from "../../lib/auth-context";
import { mongoDBRequest } from "../../services/api/dbRequest";
import Clock from "../../components/clock/clock.component";
import { useNavigate } from "react-router-dom";
import useWindowDimensions from "../../utils/width";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import TimelineIcon from "@mui/icons-material/Timeline";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import QueryStatsIcon from "@mui/icons-material/QueryStats";

import Box from "@mui/material/Box";

import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import Logout from "@mui/icons-material/Logout";
import SettingsIcon from "@mui/icons-material/Settings";

import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";

const DashBoard = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const theme = useContext(ThemeContext);
  const auth = useContext(AuthContext);
  const tab = auth.tab;
  const setTab = auth.setTab;
  const [isLoading, setIsloading] = useState(false);

  const handleLogOut = () => {
    handleClose();
    auth.setLoading(true);
    const TC_TOKEN_AUTH = localStorage.getItem("TC_TOKEN_AUTH");
    mongoDBRequest("sign-out", "POST", { TC_TOKEN_AUTH: TC_TOKEN_AUTH })
      .then((data) => {
        if (data === "goodbye") {
          localStorage.removeItem("TC_TOKEN_AUTH");
          localStorage.removeItem("user");
          localStorage.removeItem("backtest-mode");
          localStorage.removeItem("market_times_state");
          localStorage.removeItem("inprogress-mode");
          localStorage.removeItem("user");
          localStorage.removeItem("pre-trade-checklist");
          localStorage.removeItem("screen");
          localStorage.removeItem("view-mode");
          localStorage.removeItem("missed-trade");
          localStorage.removeItem("calendar-view-mode");
          localStorage.removeItem("backtest-active");
          localStorage.removeItem("rotater");
          localStorage.removeItem("trade-portal");
          localStorage.removeItem("TC-status");
          localStorage.removeItem("equityCurve");
          localStorage.removeItem("equity-mode");
          localStorage.removeItem("view-date");
          localStorage.removeItem("year");
          localStorage.removeItem("balanceIC");
          localStorage.removeItem("balancePC");
          navigate("/");
          setTimeout(() => {
            window.location.reload(false);
            localStorage.removeItem("theme");
          }, 400);
        }
      })
      .catch((err) => {
        auth.throwMessage("error", JSON.stringify(err));
      });
  };
  const handleReload = () => {
    window.history.pushState({}, document.title, window.location.pathname);
    window.location.reload();
  };
  const handleDarkMode = (event) => {
    handleClose();
    if (theme[0] === "light") {
      theme[1]("dark");
      localStorage.setItem("theme", "dark");
    } else if (theme[0] === "dark") {
      theme[1]("light");
      localStorage.setItem("theme", "light");
    }
  };
  const handleScreenPick = (num) => {
    localStorage.removeItem("backtest-active");
    setTab(num);
    localStorage.setItem("screen", num);
  };
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const list = () => {
    return (
      <Box
        sx={{
          backgroundColor: theme[0] === "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
          width: width < 2320 ? 220 : "100vw",
          height: "100%",
          color: theme[0] === "dark" ? "#FCFCFF" : "black",
          paddingTop: "1em",
          display: "flex",
          flexDirection: "column",
          justifyContent: width > 2320 ? "space-evenly" : "space-between",
          alignItems: width < 2320 ? "flex-start" : "center",
        }}
        role="presentation"
        onClick={toggleDrawer("left", false)}
        onKeyDown={toggleDrawer("left", false)}
      >
        <List>
          {/* Trade Assist */}
          {["Calendar", "Tracker", "Analyser", "Trading Plan"].map(
            (text, index) => {
              return (
                <ListItem key={text}>
                  <ListItemButton
                    onClick={() => handleScreenPick(index)}
                    sx={{ width: "100%", marginLeft: "13px" }}
                  >
                    <ListItemIcon
                      sx={{
                        color: theme[0] === "dark" ? "#FCFCFF" : "black",
                        marginLeft: "-13px",
                      }}
                    >
                      {index === 0 ? (
                        <CalendarMonthIcon />
                      ) : index === 1 ? (
                        <TimelineIcon />
                      ) : index === 2 ? (
                        <QueryStatsIcon />
                      ) : index === 3 ? (
                        <LibraryBooksIcon />
                      ) : index === 4 ? (
                        <PrecisionManufacturingIcon />
                      ) : null}
                    </ListItemIcon>
                    <ListItemText primary={text} />
                  </ListItemButton>
                </ListItem>
              );
            }
          )}
        </List>
        <p
          style={{
            color: theme[0] === "dark" ? "#FCFCFF" : "black",
            fontSize: "0.7em",
            letterSpacing: "1px",
            textAlign: "center",
            margin: "1em auto",
          }}
        >
          {JSON.parse(localStorage.getItem("user")).name}
        </p>
      </Box>
    );
  };
  useEffect(() => {
    setIsloading(true);
    const screen = localStorage.getItem("screen");
    setTab(Number(screen));
    setIsloading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (width < 840) {
      auth.handleBacktestingMode(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width]);

  const [openSettings, setOpenSettings] = useState(false);
  // const handleOpenSettings = () => setOpenSettings(true);
  const handleCloseSettings = () => setOpenSettings(false);

  return (
    <>
      <SettingsModal open={openSettings} onClose={handleCloseSettings} />
      <Drawer
        anchor={"left"}
        open={state["left"]}
        onClose={toggleDrawer("left", false)}
      >
        {list()}
      </Drawer>
      <AppBar
        position="static"
        sx={{
          backgroundColor: theme[0] === "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
          borderBottom: "1px solid #4F4F4F",

          margin: "auto",
        }}
      >
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "260px",
            }}
          >
            <IconButton
              size="large"
              edge="start"
              aria-label="menu"
              sx={{
                mr: 2,
                color: theme[0] === "dark" ? "#FCFCFF" : "black",
              }}
              onClick={toggleDrawer("left", true)}
            >
              <MenuIcon />
            </IconButton>

            {width >= 899 ? (
              <Tooltip title="Reload">
                <Typography
                  onClick={() => handleReload(tab)}
                  variant="h6"
                  sx={{
                    cursor: "pointer",
                    color: theme[0] === "dark" ? "#FCFCFF" : "black",
                    letterSpacing: "2px",
                    fontSize: width >= 899 ? "0.85em" : "0.68em",
                  }}
                >
                  {tab === 1
                    ? auth.backtestingMode
                      ? "BT Tracker"
                      : "Tracker"
                    : tab === 0
                    ? auth.backtestingMode
                      ? "BT Calendar"
                      : "Calendar"
                    : tab === 2
                    ? "Analyser"
                    : tab === 3
                    ? "Trading Plan"
                    : tab === 4
                    ? "Trade Assist"
                    : ""}
                </Typography>
              </Tooltip>
            ) : null}
          </div>
          <Clock width={width} />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              width: "220px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Tooltip title="Settings">
                <IconButton
                  onClick={handleClick}
                  size="small"
                  aria-controls={open ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                >
                  <Avatar
                    sx={{
                      marginRight: "-5px",
                      height: "35px",
                      width: "35px",
                      color:
                        theme[0] !== "dark" ? "#FCFCFF" : "rgba(40, 42, 46)",
                      backgroundColor:
                        theme[0] !== "dark" ? "#FCFCFF" : "rgba(40, 42, 46)",
                    }}
                  >
                    <SettingsIcon
                      sx={{
                        height: "31px",
                        width: "31px",
                        color:
                          theme[0] === "dark" ? "#FCFCFF" : "rgba(40, 42, 46)",
                      }}
                    />
                  </Avatar>
                </IconButton>
              </Tooltip>
            </Box>
            <Menu
              elevation={5}
              anchorEl={anchorEl}
              id="profile-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              MenuListProps={{
                sx: {
                  backgroundColor:
                    theme[0] !== "dark" ? "#FCFCFF" : "rgba(40, 42, 46)",

                  "&:hover": {
                    backgroundColor:
                      theme[0] !== "dark" ? "#FCFCFF" : "rgba(40, 42, 46)",
                  },
                },
              }}
            >
              <MenuItem
                onClick={handleClose}
                sx={{
                  color: theme[0] === "dark" ? "#FCFCFF" : "black",
                  backgroundColor:
                    theme[0] !== "dark" ? "#FCFCFF" : "rgba(40, 42, 46)",
                  "&:hover": {
                    backgroundColor:
                      theme[0] !== "dark" ? "#FCFCFF" : "rgba(40, 42, 46)",
                  },
                }}
              >
                <FormControlLabel
                  sx={{
                    color: theme[0] === "dark" ? "#FCFCFF" : "rgba(40, 42, 46)",
                    marginLeft: "5px",
                  }}
                  control={
                    <Switch
                      inputProps={{ "aria-label": "Light Or Dark Mode" }}
                    />
                  }
                  label={theme[0] === "dark" ? "Dark" : "Light"}
                  name="light or dark mode"
                  checked={theme[0] === "dark" ? true : false}
                  onChange={handleDarkMode}
                />
              </MenuItem>
              <Divider
                sx={{
                  backgroundColor:
                    theme[0] === "dark" ? "#FCFCFF" : "rgba(40, 42, 46)",
                  width: "90%",

                  marginLeft: "7px",
                }}
              />

              {/* <MenuItem
                    onClick={handleOpenSettings}
                    sx={{
                      marginLeft: "8px",
                      color: theme[0] === "dark" ? "#FCFCFF" : "black",
                      backgroundColor:
                        theme[0] !== "dark" ? "#FCFCFF" : "rgba(40, 42, 46)",
                      "&:hover": {
                        backgroundColor:
                          theme[0] !== "dark" ? "#FCFCFF" : "rgba(40, 42, 46)",
                      },
                    }}
                  >
                    <ListItemIcon>
                      <Settings
                        fontSize="small"
                        sx={{
                          color:
                            theme[0] === "dark"
                              ? "#FCFCFF"
                              : "rgba(40, 42, 46)",
                        }}
                      />
                    </ListItemIcon>
                    Settings
                  </MenuItem> */}

              <MenuItem
                onClick={handleLogOut}
                sx={{
                  marginLeft: "8px",
                  color: theme[0] === "dark" ? "#FCFCFF" : "black",
                  backgroundColor:
                    theme[0] !== "dark" ? "#FCFCFF" : "rgba(40, 42, 46)",
                  "&:hover": {
                    backgroundColor:
                      theme[0] !== "dark" ? "#FCFCFF" : "rgba(40, 42, 46)",
                  },
                }}
              >
                <ListItemIcon>
                  <Logout
                    fontSize="small"
                    sx={{
                      color:
                        theme[0] === "dark" ? "#FCFCFF" : "rgba(40, 42, 46)",
                    }}
                  />
                </ListItemIcon>
                Logout
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <Wrapper maxWidth="auto">{!isLoading && <Screens />}</Wrapper>
    </>
  );
};

export default DashBoard;

/* <div
style={{
  fontSize: "16px",
  color: "#FCFCFF",
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
  width: "100vw",
}}
>
<p>Mobile Version Coming Soon....</p>
</div> */

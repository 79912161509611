import { React, useContext, useState } from "react";

import dayjs from "dayjs";

import ThemeContext from "../../lib/theme-context";
import AuthContext from "../../lib/auth-context";
import { DataFetcher } from "../../utils/dataFetcher";

import CustomSelect from "../select/index";
import CustomSingleTextField from "../textfields/singleRow";
import CustomDateTimePicker from "../dateTimePickers/DateTimePicker";

import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";

import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

import SaveIcon from "@mui/icons-material/Save";
import Tooltip from "@mui/material/Tooltip";
import useWindowDimensions from "../../utils/width";
import CloseIcon from "@mui/icons-material/Close";

const Deposit = ({ open, onClose }) => {
  const auth = useContext(AuthContext);
  const throwMessage = auth?.throwMessage;
  const theme = useContext(ThemeContext);
  const [transactionType, setTransactionType] = useState("withdrawal");
  const [transanctionNotes, setTransanctionNotes] = useState("");
  const [transanctionAmount, setTransanctionAmount] = useState(0);
  const [transanctionReason, setTransanctionReason] = useState("");
  const [transanctionDate, setTransanctionDate] = useState(dayjs(new Date()));

  const [formSubmitted, setFormSubmitted] = useState(false);

  const submitTransaction = async (transaction, handleCloseNewDeposit) => {
    if (transanctionNotes && transanctionAmount && transanctionReason) {
      if (!formSubmitted);
      setFormSubmitted(true);
      const schema = {
        transanctionNotes: transanctionNotes,
        transanctionAmount: transanctionAmount,
        transanctionReason: transanctionReason,
        transactionType: transaction,
        date: transanctionDate,
      };
      const result = await DataFetcher("new-transaction", schema);

      if (result) {
        throwMessage("success", "Success");
        handleCloseNewDeposit();
        setTransanctionAmount(0);
        setTransanctionReason("");
        setTransanctionNotes("");
        setTransanctionDate(dayjs(new Date()));
        setFormSubmitted(false);
      } else {
        throwMessage("error", "Something went wrong");
        setFormSubmitted(false);
      }
    } else {
      throwMessage("warning", "Missing Fields");
    }
  };

  const { width } = useWindowDimensions();

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "100%",
        height: "100%",
        outline: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
 
        "&::WebkitScrollbar": { width: 0 },
        p: 1,
      }}
    >
      <Grid
        item
        xs={12}
        container
        gap={4}

        sx={{
          "&::WebkitScrollbar": { width: 0 },
          // height: width <= 800 ? "100%" : "auto",
      
          // transform: width >= 800 ? "translate(-50%, -50%)" : null,
          // position: "absolute",
          // top: width >= 800 ? "50%" : null,
          // left: width >= 800 ? "50%" : null,
          // overflowY: "scroll",
          // overflowX: "hidden",
          // color: "rgba(40, 42, 46)",
          maxWidth: width <= 800 ? "100% !important" : "50% !important",
          backgroundColor: theme[0] === "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
          border:
            theme[0] === "dark"
              ? "1px solid #FCFCFF"
              : "1px solid rgba(37,37,37)",

          outline: "none",
          padding: '30px',
          borderRadius: "10px",
          overflowY: "scroll !important",
          height: "100%",
          maxHeight:  width <= 800 ? "540px" : "440px" ,
       
        }}
      >
        <Grid
          container
          gap={4}
          justifyContent="center"
          alignItems="center"
          sx={{
          overflowY: "scroll !important",
            overflowX: 'hidden !important'
            
          }}
        >
          <Grid
            item
            xs={12}
            sx={{ color: theme[0] === "dark" ? "#FCFCFF" : "black" }}
          >
            <h3
              style={{
                textAlign: "center",
                fontWeight: "300",
                borderBottom:
                  theme[0] === "dark"
                    ? " 1px solid #FCFCFF"
                    : "1px solid rgba(37,37,37)",
                maxWidth: "400px",
                margin: "0 auto 0 auto",
                padding: "0 0 10px 0",
              }}
            >
              {transactionType === "withdrawal"
                ? "Withdrawal"
                : transactionType === "deposit"
                ? "Deposit"
                : null}
            </h3>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ color: theme[0] === "dark" ? "#FCFCFF" : "black" }}
          >
            <FormControl
              sx={{ display: "flex", alignItems: "center" }}
              onChange={(e) => setTransactionType(e.target.value)}
            >
              <RadioGroup row>
                <FormControlLabel
                  checked={transactionType === "withdrawal"}
                  value="withdrawal"
                  control={
                    <Radio
                      sx={{
                        color:
                          theme[0] === "dark"
                            ? "#FCFCFF !important"
                            : "rgba(37,37,37) !important",
                        "&.Mui-checked": {
                          color:
                            theme[0] === "dark"
                              ? "#FCFCFF !important"
                              : "rgba(37,37,37) !important",
                        },
                      }}
                    />
                  }
                  label="Withdrawal"
                />
                <FormControlLabel
                  value="deposit"
                  control={
                    <Radio
                      sx={{
                        color:
                          theme[0] === "dark"
                            ? "#FCFCFF !important"
                            : "rgba(37,37,37) !important",
                        "&.Mui-checked": {
                          color:
                            theme[0] === "dark"
                              ? "#FCFCFF !important"
                              : "rgba(37,37,37) !important",
                        },
                      }}
                    />
                  }
                  label="Deposit"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={width < 550 ? 12 : 5}>
            <CustomDateTimePicker
              size="small"
              label={
                transactionType === "withdrawal"
                  ? "Withdrawal Date"
                  : transactionType === "deposit"
                  ? "Deposit Date"
                  : ""
              }
              value={transanctionDate}
              onChange={(newValue) => setTransanctionDate(newValue.$d)}
            />
          </Grid>
          <Grid item xs={width < 550 ? 12 : 5}>
            <CustomSingleTextField
              size="small"
              type="number"
              value={transanctionAmount}
              label={
                transactionType === "withdrawal"
                  ? "Withdrawal Amount"
                  : transactionType === "deposit"
                  ? "Deposit Amount"
                  : ""
              }
              onChange={(e) => setTransanctionAmount(e.target.value)}
            />
          </Grid>
          <Grid item xs={width < 550 ? 12 : 5}>
            <CustomSelect
              size="small"
              inputLabel={
                transactionType === "withdrawal"
                  ? "Withdrawal Type"
                  : transactionType === "deposit"
                  ? "Deposit Type"
                  : ""
              }
              value={transanctionReason}
              label={
                transactionType === "withdrawal"
                  ? "Withdrawal Type"
                  : transactionType === "deposit"
                  ? "Deposit Type"
                  : ""
              }
              onChange={(e) => setTransanctionReason(e.target.value)}
              menuItemArray={JSON.stringify([
                { value: "Personal", label: "Personal Capital" },
                // { value: "Investment", label: "Investment Capital" },
                { value: "Investor", label: "Investor Financing" },
              ])}
            />
          </Grid>
          <Grid item xs={width < 550 ? 12 : 5}>
            <CustomSingleTextField
              size="small"
              value={transanctionNotes}
              label={
                transactionType === "withdrawal"
                  ? "Withdrawal Note"
                  : transactionType === "deposit"
                  ? "Deposit Note"
                  : ""
              }
              onChange={(e) => setTransanctionNotes(e.target.value)}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "0 auto",
              gap: "30px",
            }}
          >
            <Tooltip title="Save">
              <SaveIcon
                sx={{
                  color: formSubmitted ? "lightGrey" : "#26a65d",
                  cursor: "pointer",
                }}
                fontSize="large"
                onClick={() => submitTransaction(transactionType, onClose)}
              />
            </Tooltip>
            <Tooltip title="Close">
              <CloseIcon
                fontSize="large"
                sx={{
                  color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
                  cursor: "pointer",
                  textAlign: "center",
                }}
                onClick={() => onClose()}
              />
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default Deposit;

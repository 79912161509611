const fx = require("simple-fxtrade");

// Just an example, you need an async function to use async / await
const oandaConnect = async () => {
  // Configure api key, you can set env: OANDA_API_KEY instead and remove this call
  fx.configure({
    apiKey: process.env.REACT_APP_OANDA_API_KEY,
    live: false,
  });

  // Get accounts for authorized user (using OANDA_API_KEY env var)
  const {
    accounts: [{ id }],
  } = await fx.accounts();

  // Set the id context for all future api calls
  fx.setAccount(id);
};

module.exports = oandaConnect;

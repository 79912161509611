import DoneIcon from "@mui/icons-material/Done";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import markets from "./markets";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
// import moment from "moment-timezone";
// import WatchIcon from "@mui/icons-material/Watch";
// import WatchOffIcon from "@mui/icons-material/WatchOff";



const checkStopLossType = (p) => {
  const pairObject = markets.find((m) => m.ticker === p);
  if (pairObject?.multiplier === 10) {
    return "Ticks";
  } else if (pairObject?.multiplier === 1) {
    return "Pips";
  }
};

// function adjustForDST(sessionTime, pairLabel) {
//   // Define the time zone and trading hours for each pair
//   const timeZones = {
//     "AUD/CAD": { timezone: "Australia/Sydney", offset: "America/Toronto" }, // Sydney (AET) and Toronto (EST)
//     "AUD/CHF": { timezone: "Australia/Sydney", offset: "Europe/Zurich" }, // Sydney (AET) and Zurich (CET)
//     "AUD/JPY": { timezone: "Australia/Sydney", offset: "Asia/Tokyo" }, // Sydney (AET) and Tokyo (JST)
//     "AUD/NZD": { timezone: "Australia/Sydney", offset: "Pacific/Auckland" }, // Sydney (AET) and Auckland (NZDT)
//     "AUD/USD": { timezone: "Australia/Sydney", offset: "America/New_York" }, // Sydney (AET) and New York (EST/EDT)
//     "CAD/JPY": { timezone: "America/Toronto", offset: "Asia/Tokyo" }, // Toronto (EST) and Tokyo (JST)
//     "CAD/CHF": { timezone: "America/Toronto", offset: "Europe/Zurich" }, // Toronto (EST) and Zurich (CET)
//     "CHF/JPY": { timezone: "Europe/Zurich", offset: "Asia/Tokyo" }, // Zurich (CET) and Tokyo (JST)
//     "GBP/AUD": { timezone: "Europe/London", offset: "Australia/Sydney" }, // London (GMT/BST) and Sydney (AET)
//     "GBP/CAD": { timezone: "Europe/London", offset: "America/Toronto" }, // London (GMT/BST) and Toronto (EST)
//     "GBP/CHF": { timezone: "Europe/London", offset: "Europe/Zurich" }, // London (GMT/BST) and Zurich (CET)
//     "GBP/JPY": { timezone: "Europe/London", offset: "Asia/Tokyo" }, // London (GMT/BST) and Tokyo (JST)
//     "GBP/NZD": { timezone: "Europe/London", offset: "Pacific/Auckland" }, // London (GMT/BST) and Auckland (NZDT)
//     "GBP/USD": { timezone: "Europe/London", offset: "America/New_York" }, // London (GMT/BST) and New York (EST/EDT)
//     "EUR/USD": { timezone: "Europe/Paris", offset: "America/New_York" }, // Paris (CET/CEST) and New York (EST/EDT)
//     "EUR/AUD": { timezone: "Europe/Paris", offset: "Australia/Sydney" }, // Paris (CET/CEST) and Sydney (AET)
//     "EUR/CAD": { timezone: "Europe/Paris", offset: "America/Toronto" }, // Paris (CET/CEST) and Toronto (EST)
//     "EUR/GBP": { timezone: "Europe/Paris", offset: "Europe/London" }, // Paris (CET/CEST) and London (GMT/BST)
//     "EUR/JPY": { timezone: "Europe/Paris", offset: "Asia/Tokyo" }, // Paris (CET/CEST) and Tokyo (JST)
//     "EUR/CHF": { timezone: "Europe/Paris", offset: "Europe/Zurich" }, // Paris (CET/CEST) and Zurich (CET)
//     "EUR/NZD": { timezone: "Europe/Paris", offset: "Pacific/Auckland" }, // Paris (CET/CEST) and Auckland (NZDT)
//     "NZD/CAD": { timezone: "Pacific/Auckland", offset: "America/Toronto" }, // Auckland (NZDT) and Toronto (EST)
//     "NZD/CHF": { timezone: "Pacific/Auckland", offset: "Europe/Zurich" }, // Auckland (NZDT) and Zurich (CET)
//     "NZD/JPY": { timezone: "Pacific/Auckland", offset: "Asia/Tokyo" }, // Auckland (NZDT) and Tokyo (JST)
//     "NZD/USD": { timezone: "Pacific/Auckland", offset: "America/New_York" }, // Auckland (NZDT) and New York (EST/EDT)
//     "USD/CHF": { timezone: "America/New_York", offset: "Europe/Zurich" }, // New York (EST/EDT) and Zurich (CET)
//     "USD/JPY": { timezone: "America/New_York", offset: "Asia/Tokyo" }, // New York (EST/EDT) and Tokyo (JST)
//     "USD/CAD": { timezone: "America/New_York", offset: "America/Toronto" }, // New York (EST/EDT) and Toronto (EST)
//     AUS200: { timezone: "Australia/Sydney", offset: "Australia/Sydney" }, // Sydney (AET)
//     CHN50: { timezone: "Asia/Shanghai", offset: "America/New_York" }, // Shanghai (CST) and New York (EST/EDT)
//     ESP35: { timezone: "Europe/Madrid", offset: "America/New_York" }, // Madrid (CET/CEST) and New York (EST/EDT)
//     EUSTX50: { timezone: "Europe/Paris", offset: "America/New_York" }, // Paris (CET/CEST) and New York (EST/EDT)
//     FRA40: { timezone: "Europe/Paris", offset: "America/New_York" }, // Paris (CET/CEST) and New York (EST/EDT)
//     GER30: { timezone: "Europe/Berlin", offset: "America/New_York" }, // Berlin (CET/CEST) and New York (EST/EDT)
//     HKG33: { timezone: "Asia/Hong_Kong", offset: "Asia/Hong_Kong" }, // Hong Kong (HKT)
//     JPN225: { timezone: "Asia/Tokyo", offset: "Asia/Tokyo" }, // Tokyo (JST)
//     NAS100: { timezone: "America/New_York", offset: "Europe/London" }, // New York (EST/EDT) and London (GMT/BST)
//     SPX500: { timezone: "America/New_York", offset: "Europe/London" }, // New York (EST/EDT) and London (GMT/BST)
//     UK100: { timezone: "Europe/London", offset: "America/New_York" }, // London (GMT/BST) and New York (EST/EDT)
//     US2000: { timezone: "America/New_York", offset: "Europe/London" }, // New York (EST/EDT) and London (GMT/BST)
//     US30: { timezone: "America/New_York", offset: "Europe/London" }, // New York (EST/EDT) and Lo

//     COFFEE: { timezone: "America/New_York", offset: "America/New_York" }, // New York
//     COPPER: { timezone: "America/New_York", offset: "America/New_York" }, // New York
//     CORN: { timezone: "America/New_York", offset: "America/New_York" }, // New York
//     GOLD: { timezone: "America/New_York", offset: "America/New_York" }, // New York
//     NGAS: { timezone: "America/New_York", offset: "America/New_York" }, // New York
//     WHEAT: { timezone: "America/New_York", offset: "America/New_York" }, // New York
//     SILVER: { timezone: "America/New_York", offset: "America/New_York" }, // New York
//     SOY: { timezone: "America/New_York", offset: "America/New_York" }, // New York
//     SUGAR: { timezone: "America/New_York", offset: "America/New_York" }, // New York
//     UKOIL: { timezone: "Europe/London", offset: "America/New_York" }, // London
//     USOIL: { timezone: "America/New_York", offset: "America/New_York" }, // New York

//     BTCUSD: { timezone: "America/New_York", offset: "America/New_York" }, // New York
//     ETHUSD: { timezone: "America/New_York", offset: "America/New_York" }, // New York
//   };

//   // Extract the session start and end time (in UTC)
//   const [startTime, endTime] = sessionTime?.split("-");

//   // Get the relevant time zone for the currency pair
//   const timeZone = timeZones[pairLabel] || { timezone: "UTC", offset: "UTC" };

//   // Convert the start and end times to the respective local times using Moment.js
//   const startMoment = moment
//     .utc(startTime, "HHmm")
//     .tz(timeZone.timezone, true);
//   const endMoment = moment.utc(endTime, "HHmm").tz(timeZone.offset, true);

//   // Adjust for DST using Moment.js time zone handling
//   const adjustedStart = startMoment.format("HHmm"); // Output in local time
//   const adjustedEnd = endMoment.format("HHmm"); // Output in local time

//   return `${adjustedStart}-${adjustedEnd}`;
// }

const columnsSimple = (
  theme,
  throwMessage,
  idTradeGenerator,
  runDurationCal,
  handleOpenJournal,
  auth
) => {
  let array = [
    {
      field: "pair",
      headerName: "Instrument",
      width: 175,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        if (!params.row.pair) {
          return "";
        } else {
          return (
            <p
              style={{
                color: theme[0] === "dark" ? "#FCFCFF" : "black",
              }}
            >
              {params.row.pair}
            </p>
          );
        }
      },
    },

    {
      field: "journalID",
      headerName: "Journal",
      width: 95,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        if (!params.row.pair) {
          return "";
        } else {
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                width: "100%",
                margin: "0 15px 0 0",
              }}
            >
              <p
                style={{ cursor: "pointer", margin: "0 0 0 10px" }}
                onClick={() => {
                  if (!params.row.journalID) {
                    throwMessage("error", "No Journal Found");
                    handleOpenJournal(params.row, true);
                  } else {
                    handleOpenJournal(params.row, true);
                  }
                }}
              >
                {params.row.journalComplete === "true" ? (
                  <DoneIcon
                    style={{
                      color: "26a65d",
                    }}
                  />
                ) : (
                  <MenuBookIcon
                    style={{
                      color: "#f23645",
                    }}
                  />
                )}
              </p>
            </div>
          );
        }
      },
    },
    {
      field: "foreCastId",
      headerName: "Forecast",
      width: 93,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              width: "100%",
              margin: "0 15px 0 0",
            }}
          >
            {params.row.foreCastId === "Empty" ? (
              <HorizontalRuleIcon />
            ) : params.row.foreCastId ? (
              <p
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  localStorage.setItem(
                    "forecast-to-view",
                    params.row.foreCastId
                  );
                  localStorage.setItem("screen", 0);
                  auth.setTab(0);
                  localStorage.setItem("from-tracker", true);
                }}
              >
                <DoneIcon
                  style={{
                    color: "26a65d",
                  }}
                />
              </p>
            ) : (
              <QueryStatsIcon
                style={{
                  color: "#f23645",
                }}
              />
            )}
          </div>
        );
      },
    },
    {
      field: "entryDate",
      headerName: "Entry Date",
      width: 156,
      headerAlign: "center",
      align: "center",

      renderCell: (params) => {
        if (!params.row.entryDate) {
          return "";
        } else {
          return <p>{params.row.entryDate}</p>;
        }
      },
    },

    {
      field: "profitLossPercentagePC",
      headerName: "% PC",
      width: 156,
      valueFormatter: ({ value }) => `${value} %`,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        if (
          params.row.equityType === "Personal & Investor Capital" ||
          params.row.equityType === "Personal Capital" ||
          params.row.equityType === "Backtest"
        ) {
          if (!params.row.profitLossPercentagePC) {
            return <p style={{ color: "#ED9E0E" }}>0 %</p>;
          } else {
            return (
              <p
                style={{
                  color:
                    params.row.profitLossPercentagePC < 0
                      ? "#f23645"
                      : params.row.profitLossPercentagePC > 0
                      ? "#26a65d"
                      : "#ED9E0E",
                }}
              >
                {Math.abs(params.row.profitLossPercentagePC)} %
              </p>
            );
          }
        } else {
          return (
            <HorizontalRuleIcon
              sx={{ color: theme[0] === "dark" ? "#FCFCFF" : "black" }}
            />
          );
        }
      },
    },
    {
      field: "profitLossPercentageIC",
      headerName: "% IC",
      width: 156,
      valueFormatter: ({ value }) => `${value} %`,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        if (
          params.row.equityType === "Personal & Investor Capital" ||
          params.row.equityType === "Investor Capital"
        ) {
          if (!params.row.profitLossPercentageIC) {
            return <p></p>;
          } else {
            return (
              <p
                style={{
                  color:
                    params.row.profitLossPercentageIC < 0
                      ? "#f23645"
                      : params.row.profitLossPercentageIC > 0
                      ? "#26a65d"
                      : "#ED9E0E",
                }}
              >
                {Math.abs(params.row.profitLossPercentageIC)} %
              </p>
            );
          }
        } else {
          return (
            <HorizontalRuleIcon
              sx={{ color: theme[0] === "dark" ? "#FCFCFF" : "black" }}
            />
          );
        }
      },
    },

    {
      field: "direction",
      headerName: "Direction",
      width: 156,
      headerAlign: "center",
      align: "center",

      renderCell: (params) => {
        if (!params.row.direction) {
          return "";
        } else {
          return (
            <p
              style={{
                color:
                  params.row.direction === "Short"
                    ? "#db0671"
                    : params.row.direction === "Long"
                    ? "#0079cf"
                    : "",
              }}
            >
              {params.row.direction}
            </p>
          );
        }
      },
    },
    {
      field: "outcomePC",
      headerName: "Outcome PC",
      width: 156,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        if (!params.row.outcomePC) {
          return (
            <HorizontalRuleIcon
              sx={{ color: theme[0] === "dark" ? "#FCFCFF" : "black" }}
            />
          );
        } else {
          return (
            <p
              style={{
                color:
                  params.row.outcomePC === "Loss"
                    ? "#f23645"
                    : params.row.outcomePC === "Win"
                    ? "#26a65d"
                    : params.row.outcomePC === "Breakeven"
                    ? "#ED9E0E"
                    : "",
              }}
            >
              {params.row.outcomePC}
            </p>
          );
        }
      },
    },
    {
      field: "outcomeIC",
      headerName: "Outcome IC",
      width: 156,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        if (!params.row.outcomeIC) {
          return (
            <HorizontalRuleIcon
              sx={{ color: theme[0] === "dark" ? "#FCFCFF" : "black" }}
            />
          );
        } else {
          return (
            <p
              style={{
                color:
                  params.row.outcomeIC === "Loss"
                    ? "#f23645"
                    : params.row.outcomeIC === "Win"
                    ? "#26a65d"
                    : params.row.outcomeIC === "Breakeven"
                    ? "#ED9E0E"
                    : "",
              }}
            >
              {params.row.outcomeIC}
            </p>
          );
        }
      },
    },
    {
      field: "grade",
      headerName: "Grade",
      width: 156,
      headerAlign: "center",
      align: "center",

      renderCell: (params) => {
        if (!params.row.grade) {
          return "";
        } else {
          return (
            <p
              style={{
                color:
                  params.row.grade === "Low Risk"
                    ? "#26a65d"
                    : params.row.grade === "High Risk"
                    ? "#f23645"
                    : "",
              }}
            >
              {params.row.grade}
            </p>
          );
        }
      },
    },

    {
      field: "stopLossPipsPC",
      headerName: "Stop Loss PC",
      width: 156,
      headerAlign: "center",
      align: "center",

      renderCell: (params) => {
        if (
          params.row.equityType === "Personal & Investor Capital" ||
          params.row.equityType === "Personal Capital" ||
          params.row.equityType === "Backtest"
        ) {
          if (!params.row.stopLossPipsPC) {
            return "";
          } else {
            return (
              <p>
                {params.row.stopLossPipsPC} {checkStopLossType(params.row.pair)}
              </p>
            );
          }
        } else {
          return (
            <HorizontalRuleIcon
              sx={{ color: theme[0] === "dark" ? "#FCFCFF" : "black" }}
            />
          );
        }
      },
    },
    {
      field: "stopLossPipsIC",
      headerName: "Stop Loss IC",
      width: 156,
      headerAlign: "center",
      align: "center",

      renderCell: (params) => {
        if (
          params.row.equityType === "Personal & Investor Capital" ||
          params.row.equityType === "Investor Capital"
        ) {
          if (!params.row.stopLossPipsIC) {
            return "";
          } else {
            return (
              <p>
                {params.row.stopLossPipsIC} {checkStopLossType(params.row.pair)}
              </p>
            );
          }
        } else {
          return (
            <HorizontalRuleIcon
              sx={{ color: theme[0] === "dark" ? "#FCFCFF" : "black" }}
            />
          );
        }
      },
    },

    {
      field: "optionType",
      headerName: "Option Type",
      width: 110,
      headerAlign: "center",
      align: "center",
      editable: true,
      renderCell: (params) => {
        
          return (
            <p>{params.row.optionType}</p>
          
        );
      },
    },
    {
      field: "premium",
      headerName: "Premium",
      width: 110,
      headerAlign: "center",
      align: "center",
      editable: true,
      renderCell: (params) => {
        return (
          <p>{params.row.premium}</p>
        );
      },
    },

    {
      field: "duration",
      headerName: "Duration",
      width: 156,
      headerAlign: "center",
      align: "center",

      renderCell: (params) => {
        return runDurationCal(params);
      },
    },

    // {
    //   field: "profitLossDollar",
    //   headerName: "$ $ $",
    //   width: 157,
    //   valueFormatter: ({ value }) => `$ ${value}`,
    //   headerAlign: "center",
    //   align: "center",
    //   renderCell: (params) => {
    //     if (!params.row.profitLossDollar) {
    //       return "$ 0";
    //     } else {
    //       return (
    //         <p
    //           style={{
    //             color: params.row.profitLossDollar < 0 ? "#f23645" : "#26a65d",
    //           }}
    //         >
    //           $ {Math.abs(params.row.profitLossDollar)}
    //         </p>
    //       );
    //     }
    //   },
    // },
  ];

  const mode = localStorage.getItem("equity-mode");

  const PC_Fields = [
    "optionType",
    "premium",
    "outcomePC",
    "profitLossPercentagePC",
    "profitLossDollarPersonal",
    "feePC",
    "returnTotalsPersonal",
    "stopLossPipsPC",
    "openPricePC",
    "closePricePC",
  ];
  const IC_Fields = [
    "optionType",
    "premium",
    "outcomeIC",
    "profitLossPercentageIC",
    "profitLossDollarInvestor",
    "feeIC",
    "returnTotalsInvestor",
    "stopLossPipsIC",
    "openPriceIC",
    "closePriceIC",
  ];

  const OPTIONS_Fields = [
    
    "outcomeIC",
    "profitLossPercentageIC",
    "profitLossDollarInvestor",
    "feeIC",
    "returnTotalsInvestor",
    "stopLossPipsIC",
    "openPriceIC",
    "closePriceIC",
    "outcomePC",
    "profitLossPercentagePC",
    "profitLossDollarPersonal",
    "feePC",
    "returnTotalsPersonal",
    "stopLossPipsPC",
    "openPricePC",
    "closePricePC",
  ];

  const ICPC_Fields = [
    "optionType",
    "premium",
 
  ];

  if (mode === "Personal Capital") {
    let filteredData;
    filteredData = array.filter((obj) => !IC_Fields.includes(obj.field));
    array = filteredData;
    return array;
  } else if (mode === "Investor Capital") {
    let filteredData;
    filteredData = array.filter((obj) => !PC_Fields.includes(obj.field));
    array = filteredData;
    return array;
  } else if (mode === "Options") {
    let filteredData;
    filteredData = array.filter((obj) => !OPTIONS_Fields.includes(obj.field));
    array = filteredData;
    return array;
  } else {
    let filteredData;
    filteredData = array.filter((obj) => !ICPC_Fields.includes(obj.field));
    array = filteredData;
    return array;
  }
};

export default columnsSimple;

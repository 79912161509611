import { React, useContext } from "react";
import ThemeContext from "../../lib/theme-context";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";

const CustomDateTimePicker = ({ label, value, onChange, size, border }) => {
  const theme = useContext(ThemeContext);

  return (
    <DateTimePicker
  
      size={size}
      sx={{
        "& label": {
          color:
            theme[0] === "dark"
              ? "#FCFCFF !important"
              : "rgba(37,37,37) !important",
        },

        width: "100%",

        "& fieldset": {
          border: border,
          // - The <fieldset> inside the Input-root
          borderColor: theme[0] === "dark" ? "#FCFCFF" : "rgba(40, 42, 46)", // - Set the Input border
        },
        "&:hover fieldset": {
          borderColor: theme[0] === "dark" ? "#FCFCFF" : "rgba(40, 42, 46)", // - Set the Input border when parent has :hover
        },
        "&.Mui-focused fieldset": {
          // - Set the Input border when parent is focused
          borderColor: theme[0] === "dark" ? "#FCFCFF" : "rgba(40, 42, 46)",
        },

        input: {
          color: theme[0] === "dark" ? "#FCFCFF" : "black",
        },
      }}
      slotProps={{
        textField: { size: size },
        popper: { placement: "auto" },
      }}
      slots={{
        openPickerIcon: () => (
          <CalendarMonthIcon
            sx={{
              color: theme[0] === "dark" ? "#FCFCFF" : "rgba(40, 42, 46)",
            }}
          />
        ),
      }}
      label={label}
      value={value ? dayjs(new Date(value)) : dayjs(new Date())}
      onChange={onChange}
    />
  );
};

export default CustomDateTimePicker;

import useWindowDimensions from "../../utils/width";
import markets from "../../lib/markets";
import moment from "moment-timezone";


const Stats = ({ trades, theme, runSpeficCalStats, }) => {

  const win = "#26a65d";
  const loss = "#f23645";
  const breakeven = "#ED9E0E";

  const { width } = useWindowDimensions();

  // stops
  const avgStopForex = (trades) => {
    const arrayOfStops = [];
    let answer;
    if (trades.length > 0) {
      for (let index = 0; index < trades.length; index++) {
        const trade = trades[index];
        const found = markets.find((p) => p.ticker === trade.pair);
        if (found.multiplier === 1) {
          arrayOfStops.push(
            trade.stopLossPipsPC ? trade.stopLossPipsPC : trade.stopLossPipsIC
          );
        }
      }
      answer = arrayOfStops?.reduce((p, c, _, a) => p + c / a.length, 0);
      return answer === 0 ? 0 : answer.toFixed(2);
    } else {
      return 0;
    }
  };
  const avgStopIndices = (trades) => {
    const arrayOfStops = [];
    let answer;
    if (trades.length > 0) {
      for (let index = 0; index < trades.length; index++) {
        const trade = trades[index];
        const found = markets.find((p) => p.ticker === trade.pair);
        if (found.baseCurrency) {
          let stop;
          stop = trade.stopLossPipsPC
            ? trade.stopLossPipsPC
            : trade.stopLossPipsIC;
          arrayOfStops.push(stop / 100);
        }
      }
      answer = arrayOfStops?.reduce((p, c, _, a) => p + c / a.length, 0);
      return answer === 0 ? 0 : answer.toFixed(2);
    } else {
      return 0;
    }
  };
  const avgCommodities = (trades) => {
    const arrayOfStops = [];
    let answer;
    if (trades.length > 0) {
      for (let index = 0; index < trades.length; index++) {
        const trade = trades[index];
        const found = markets.find((p) => p.ticker === trade.pair);

        if (found.commoditie) {
          if (found.multiplier === 10) {
            let stop;
            stop = trade.stopLossPipsPC
              ? trade.stopLossPipsPC
              : trade.stopLossPipsIC;
            arrayOfStops.push(stop / 100);
          }
          if (found.multiplier === 100) {
            let stop;
            stop = trade.stopLossPipsPC
              ? trade.stopLossPipsPC
              : trade.stopLossPipsIC;
            arrayOfStops.push(stop / 10);
          }
          if (found.multiplier === 1000) {
            // let stop;
            // stop = trade.stopLossPipsPC
            //   ? trade.stopLossPipsPC
            //   : trade.stopLossPipsIC;
            // arrayOfStops.push(stop / 1);
          }
        }
      }
      answer = arrayOfStops?.reduce((p, c, _, a) => p + c / a.length, 0);
      return answer === 0 ? 0 : answer.toFixed(2);
    } else {
      return 0;
    }
  };
  const avgStopCryto = (trades) => {
    const arrayOfStops = [];
    let answer;
    if (trades.length > 0) {
      for (let index = 0; index < trades.length; index++) {
        const trade = trades[index];
        const found = markets.find((p) => p.ticker === trade.pair);
        if (found.crypto) {
          let stop;
          stop = trade.stopLossPipsPC
            ? trade.stopLossPipsPC
            : trade.stopLossPipsIC;
          arrayOfStops.push(stop / 100);
        }
      }
      answer = arrayOfStops?.reduce((p, c, _, a) => p + c / a.length, 0);
      return answer === 0 ? 0 : answer.toFixed(2);
    } else {
      return 0;
    }
  };
  // durations
  const avgDurForex = (trades) => {
    let answer;

    const totalHoursArray = [];

    for (let index = 0; index < trades.length; index++) {
      const trade = trades[index];
      const found = markets.find((p) => p.ticker === trade.pair);
      if (found.multiplier === 1) {
        let entryDate = trade.entryDate.split(/\//);
        let exitDate = trade.exitDate.split(/\//);

        entryDate = [entryDate[1], entryDate[0], entryDate[2]].join("/");
        exitDate = [exitDate[1], exitDate[0], exitDate[2]].join("/");

        const date = {
          entryDate: entryDate,
          entryTime: trade.entryTime,
          exitDate: exitDate,
          exitTime: trade.exitTime,
        };

        const startDate = moment(
          new Date(date.entryDate + " " + date.entryTime)
        );

        const endDate = moment(new Date(date.exitDate + " " + date.exitTime));

        let hours = moment
          .duration(moment(endDate).diff(moment(startDate)))
          .asHours();

        if (!isNaN(hours)) {
          totalHoursArray.push(hours);
        }
      }
    }

    answer = totalHoursArray?.reduce((p, c, _, a) => p + c / a.length, 0);
    return 0 ? 0 : answer.toFixed(2);
  };
  const avgDurIndices = (trades) => {
    let answer;

    const totalHoursArray = [];

    for (let index = 0; index < trades.length; index++) {
      const trade = trades[index];
      const found = markets.find((p) => p.ticker === trade.pair);
      if (found.baseCurrency) {
        let entryDate = trade.entryDate.split(/\//);
        let exitDate = trade.exitDate.split(/\//);

        entryDate = [entryDate[1], entryDate[0], entryDate[2]].join("/");
        exitDate = [exitDate[1], exitDate[0], exitDate[2]].join("/");

        const date = {
          entryDate: entryDate,
          entryTime: trade.entryTime,
          exitDate: exitDate,
          exitTime: trade.exitTime,
        };

        const startDate = moment(
          new Date(date.entryDate + " " + date.entryTime)
        );

        const endDate = moment(new Date(date.exitDate + " " + date.exitTime));

        let hours = moment
          .duration(moment(endDate).diff(moment(startDate)))
          .asHours();

        if (!isNaN(hours)) {
          totalHoursArray.push(hours);
        }
      }
    }

    answer = totalHoursArray?.reduce((p, c, _, a) => p + c / a.length, 0);
    return answer === 0 ? 0 : answer.toFixed(2);
  };
  const avgDurCommodities = (trades) => {
    let answer;

    const totalHoursArray = [];

    for (let index = 0; index < trades.length; index++) {
      const trade = trades[index];
      const found = markets.find((p) => p.ticker === trade.pair);
      if (found.commoditie) {
        let entryDate = trade.entryDate.split(/\//);
        let exitDate = trade.exitDate.split(/\//);

        entryDate = [entryDate[1], entryDate[0], entryDate[2]].join("/");
        exitDate = [exitDate[1], exitDate[0], exitDate[2]].join("/");

        const date = {
          entryDate: entryDate,
          entryTime: trade.entryTime,
          exitDate: exitDate,
          exitTime: trade.exitTime,
        };

        const startDate = moment(
          new Date(date.entryDate + " " + date.entryTime)
        );

        const endDate = moment(new Date(date.exitDate + " " + date.exitTime));

        let hours = moment
          .duration(moment(endDate).diff(moment(startDate)))
          .asHours();

        if (!isNaN(hours)) {
          totalHoursArray.push(hours);
        }
      }
    }

    answer = totalHoursArray?.reduce((p, c, _, a) => p + c / a.length, 0);
    return answer === 0 ? 0 : answer.toFixed(2);
  };
  const avgDurCrypto = (trades) => {
    let answer;

    const totalHoursArray = [];

    for (let index = 0; index < trades.length; index++) {
      const trade = trades[index];
      const found = markets.find((p) => p.ticker === trade.pair);
      if (found.crypto) {
        let entryDate = trade.entryDate.split(/\//);
        let exitDate = trade.exitDate.split(/\//);

        entryDate = [entryDate[1], entryDate[0], entryDate[2]].join("/");
        exitDate = [exitDate[1], exitDate[0], exitDate[2]].join("/");

        const date = {
          entryDate: entryDate,
          entryTime: trade.entryTime,
          exitDate: exitDate,
          exitTime: trade.exitTime,
        };

        const startDate = moment(
          new Date(date.entryDate + " " + date.entryTime)
        );

        const endDate = moment(new Date(date.exitDate + " " + date.exitTime));

        let hours = moment
          .duration(moment(endDate).diff(moment(startDate)))
          .asHours();

        if (!isNaN(hours)) {
          totalHoursArray.push(hours);
        }
      }
    }

    answer = totalHoursArray?.reduce((p, c, _, a) => p + c / a.length, 0);
    return answer === 0 ? 0 : answer.toFixed(2);
  };
  // time
  const avgTimeForex = (trades) => {
    const arrayTimesEntry = [];
    const arrayTimesExit = [];
    let entry;
    let exit;

    const hhmmss_to_secs = (hhmmss) => {
      const [hh, mm] = hhmmss.split(":").map(Number);
      return hh * 3600 + mm * 60 || 24 * 3600;
    };

    if (trades.length > 0) {
      for (let index = 0; index < trades.length; index++) {
        const trade = trades[index];

        const found = markets.find((p) => p.ticker === trade.pair);

        if (found.multiplier === 1) {
          arrayTimesEntry.push(trade.entryTime);
          arrayTimesExit.push(trade.exitTime);
        }
      }
      if (arrayTimesEntry.length > 0) {
        const stageOneEntry = arrayTimesEntry.map(hhmmss_to_secs);
        const stageTwoEntry =
          stageOneEntry?.reduce((a, b) => a + b) / stageOneEntry.length;
        const stageThreeEntry = new Date(stageTwoEntry * 1000).toISOString();
        entry = moment(stageThreeEntry).toISOString();
      }
      if (arrayTimesExit.length > 0) {
        const stageOneExit = arrayTimesExit.map(hhmmss_to_secs);
        const stageTwoExit =
          stageOneExit?.reduce((a, b) => a + b) / stageOneExit.length;
        const stageThreeExit = new Date(stageTwoExit * 1000).toISOString();
        exit = moment(stageThreeExit).toISOString();
      }

      if (entry && exit) {
        return `${entry.split("T")[1].split(".")[0].slice(0, -3)}/${exit
          .split("T")[1]
          .split(".")[0]
          .slice(0, -3)}`;
      } else {
        return 0;
      }
    } else return 0;
  };
  const avgTimeIndices = (trades) => {
    const arrayTimesEntry = [];
    const arrayTimesExit = [];
    let entry;
    let exit;

    const hhmmss_to_secs = (hhmmss) => {
      const [hh, mm] = hhmmss.split(":").map(Number);
      return hh * 3600 + mm * 60 || 24 * 3600;
    };

    if (trades.length > 0) {
      for (let index = 0; index < trades.length; index++) {
        const trade = trades[index];

        const found = markets.find((p) => p.ticker === trade.pair);

        if (found.baseCurrency) {
          arrayTimesEntry.push(trade.entryTime);
          arrayTimesExit.push(trade.exitTime);
        }
      }
      if (arrayTimesEntry.length > 0) {
        const stageOneEntry = arrayTimesEntry.map(hhmmss_to_secs);
        const stageTwoEntry =
          stageOneEntry?.reduce((a, b) => a + b) / stageOneEntry.length;
        const stageThreeEntry = new Date(stageTwoEntry * 1000).toISOString();
        entry = moment(stageThreeEntry).toISOString();
      }
      if (arrayTimesExit.length > 0) {
        const stageOneExit = arrayTimesExit.map(hhmmss_to_secs);
        const stageTwoExit =
          stageOneExit?.reduce((a, b) => a + b) / stageOneExit.length;
        const stageThreeExit = new Date(stageTwoExit * 1000).toISOString();
        exit = moment(stageThreeExit).toISOString();
      }

      if (entry && exit) {
        return `${entry.split("T")[1].split(".")[0].slice(0, -3)}/${exit
          .split("T")[1]
          .split(".")[0]
          .slice(0, -3)}`;
      } else {
        return 0;
      }
    } else return 0;
  };
  const avgTimeCommodities = (trades) => {
    const arrayTimesEntry = [];
    const arrayTimesExit = [];
    let entry;
    let exit;

    const hhmmss_to_secs = (hhmmss) => {
      const [hh, mm] = hhmmss.split(":").map(Number);
      return hh * 3600 + mm * 60 || 24 * 3600;
    };

    if (trades.length > 0) {
      for (let index = 0; index < trades.length; index++) {
        const trade = trades[index];

        const found = markets.find((p) => p.ticker === trade.pair);

        if (found.commoditie) {
          arrayTimesEntry.push(trade.entryTime);
          arrayTimesExit.push(trade.exitTime);
        }
      }
      if (arrayTimesEntry.length > 0) {
        const stageOneEntry = arrayTimesEntry.map(hhmmss_to_secs);
        const stageTwoEntry =
          stageOneEntry?.reduce((a, b) => a + b) / stageOneEntry.length;
        const stageThreeEntry = new Date(stageTwoEntry * 1000).toISOString();
        entry = moment(stageThreeEntry).toISOString();
      }
      if (arrayTimesExit.length > 0) {
        const stageOneExit = arrayTimesExit.map(hhmmss_to_secs);
        const stageTwoExit =
          stageOneExit?.reduce((a, b) => a + b) / stageOneExit.length;
        const stageThreeExit = new Date(stageTwoExit * 1000).toISOString();
        exit = moment(stageThreeExit).toISOString();
      }

      if (entry && exit) {
        return `${entry.split("T")[1].split(".")[0].slice(0, -3)}/${exit
          .split("T")[1]
          .split(".")[0]
          .slice(0, -3)}`;
      } else {
        return 0;
      }
    } else return 0;
  };
  const avgTimeCrypto = (trades) => {
    const arrayTimesEntry = [];
    const arrayTimesExit = [];
    let entry;
    let exit;

    const hhmmss_to_secs = (hhmmss) => {
      const [hh, mm] = hhmmss.split(":").map(Number);
      return hh * 3600 + mm * 60 || 24 * 3600;
    };

    if (trades.length > 0) {
      for (let index = 0; index < trades.length; index++) {
        const trade = trades[index];

        const found = markets.find((p) => p.ticker === trade.pair);

        if (found.crypto) {
          arrayTimesEntry.push(trade.entryTime);
          arrayTimesExit.push(trade.exitTime);
        }
      }
      if (arrayTimesEntry.length > 0) {
        const stageOneEntry = arrayTimesEntry.map(hhmmss_to_secs);
        const stageTwoEntry =
          stageOneEntry?.reduce((a, b) => a + b) / stageOneEntry.length;
        const stageThreeEntry = new Date(stageTwoEntry * 1000).toISOString();
        entry = moment(stageThreeEntry).toISOString();
      }
      if (arrayTimesExit.length > 0) {
        const stageOneExit = arrayTimesExit.map(hhmmss_to_secs);
        const stageTwoExit =
          stageOneExit?.reduce((a, b) => a + b) / stageOneExit.length;
        const stageThreeExit = new Date(stageTwoExit * 1000).toISOString();
        exit = moment(stageThreeExit).toISOString();
      }

      if (entry && exit) {
        return `${entry.split("T")[1].split(".")[0].slice(0, -3)}/${exit
          .split("T")[1]
          .split(".")[0]
          .slice(0, -3)}`;
      } else {
        return 0;
      }
    } else return 0;
  };

  // return
  const avgReturnForex = (trades) => {
    const percents = runSpeficCalStats(
      trades.filter((t) => {
        const found = markets.find((p) => p.ticker === t.pair);
        if (found.multiplier === 1) {
          return t;
        } else {
          return null;
        }
      })
    );

    if (percents.length > 0) {
      return percents.reduce((p, c, _, a) => p + c / a.length, 0).toFixed(2);
    } else {
      return 0;
    }
  };
  const avgReturnIndices = (trades) => {
    const percents = runSpeficCalStats(
      trades.filter((t) => {
        const found = markets.find((p) => p.ticker === t.pair);

        if (found.baseCurrency) {
          return t;
        } else {
          return null;
        }
      })
    );

    if (percents.length > 0) {
      return percents.reduce((p, c, _, a) => p + c / a.length, 0).toFixed(2);
    } else {
      return 0;
    }
  };
  const avgReturnCommodities = (trades) => {
    const percents = runSpeficCalStats(
      trades.filter((t) => {
        const found = markets.find((p) => p.ticker === t.pair);

        if (found.commoditie) {
          return t;
        } else {
          return null;
        }
      })
    );

    if (percents.length > 0) {
      return percents.reduce((p, c, _, a) => p + c / a.length, 0).toFixed(2);
    } else {
      return 0;
    }
  };
  const avgReturnCrypto = (trades) => {
    const percents = runSpeficCalStats(
      trades.filter((t) => {
        const found = markets.find((p) => p.ticker === t.pair);

        if (found.crypto) {
          return t;
        } else {
          return null;
        }
      })
    );

    if (percents.length > 0) {
      return percents.reduce((p, c, _, a) => p + c / a.length, 0).toFixed(2);
    } else {
      return 0;
    }
  };



  return (
    <div
      style={{
        padding: "0.7em",
        borderRadius: "10px",
        width: "100%",
        maxWidth: "770px",
      }}
    >
      <table
        className={
          theme[0] === "dark" ? "tableHoverEffectDark" : "tableHoverEffectLight"
        }
        style={{
          tableLayout: "fixed",
          borderCollapse: "collapse",
          width: "100%",
          height: "200px",
          fontSize: width <= 700 ? "13px" : "18px",
          margin: "0 auto",
        }}
      >
        <caption
          style={{
            marginBottom: "10px",
            fontSize: width <= 700 ? "16px" : "18px",
            fontWeight: "400",
            borderBottom: `1px solid ${
              theme[0] === "dark" ? "#FCFCFF" : "black"
            }`,
            paddingBottom: "10px",
          }}
        >
          Average Stats
       
        </caption>
        <thead
          className="tableHoverEffectOff"
          style={{ fontSize: width <= 700 ? "13px" : "16px", height: "50px" }}
        >
          <tr
            style={{
              position: "sticky",
              top: "0",
              backdropFilter: "blur(10px)",
            }}
          >
            <th></th>
            <th></th>
            <th style={{ fontWeight: "400", color: win }}>Win</th>
            <th style={{ fontWeight: "400", color: breakeven }}>BE</th>
            <th style={{ fontWeight: "400", color: loss }}>Loss</th>
          </tr>
        </thead>
        <tbody>
          <tr style={{ textAlign: "center" }}>
            <th style={{ fontWeight: "400" }} scope="row"></th>
            <th style={{ fontWeight: "300" }} scope="row">
              Stop
            </th>
            <td style={{ color: win }}>
              {avgStopForex(
                trades.filter(
                  (t) => t.outcomePC === "Win" || t.outcomeIC === "Win"
                )
              )}
            </td>
            <td style={{ color: breakeven }}>
              {avgStopForex(
                trades.filter(
                  (t) =>
                    t.outcomePC === "Breakeven" || t.outcomeIC === "Breakeven"
                )
              )}
            </td>
            <td style={{ color: loss }}>
              {avgStopForex(
                trades.filter(
                  (t) => t.outcomePC === "Loss" || t.outcomeIC === "Loss"
                )
              )}
            </td>
          </tr>
          <tr style={{ textAlign: "center" }}>
            <th style={{ fontWeight: "400" }} scope="row">
              Forex
            </th>
            <th style={{ fontWeight: "300" }} scope="row">
              Duration
            </th>

            <td style={{ color: win }}>
              {avgDurForex(
                trades.filter(
                  (t) => t.outcomePC === "Win" || t.outcomeIC === "Win"
                )
              )}
            </td>
            <td style={{ color: breakeven }}>
              {" "}
              {avgDurForex(
                trades.filter(
                  (t) =>
                    t.outcomePC === "Breakeven" || t.outcomeIC === "Breakeven"
                )
              )}
            </td>
            <td style={{ color: loss }}>
              {" "}
              {avgDurForex(
                trades.filter(
                  (t) => t.outcomePC === "Loss" || t.outcomeIC === "Loss"
                )
              )}
            </td>
          </tr>
          <tr style={{ textAlign: "center" }}>
            <th style={{ fontWeight: "400" }} scope="row"></th>
            <th style={{ fontWeight: "300" }} scope="row">
              Time
            </th>

            <td style={{ color: win }}>
              {avgTimeForex(
                trades.filter(
                  (t) => t.outcomePC === "Win" || t.outcomeIC === "Win"
                )
              )}
            </td>
            <td style={{ color: breakeven }}>
              {avgTimeForex(
                trades.filter(
                  (t) =>
                    t.outcomePC === "Breakeven" || t.outcomeIC === "Breakeven"
                )
              )}
            </td>
            <td style={{ color: loss }}>
              {avgTimeForex(
                trades.filter(
                  (t) => t.outcomePC === "Loss" || t.outcomeIC === "Loss"
                )
              )}
            </td>
          </tr>
          <tr
            style={{
              textAlign: "center",
              borderBottom: `1px solid ${
                theme[0] === "dark" ? "#FCFCFF" : "black"
              }`,
            }}
          >
            <th style={{ fontWeight: "400" }} scope="row"></th>
            <th style={{ fontWeight: "300" }} scope="row">
              Return
            </th>

            <td
              style={{
                color: win,
                borderBottom: `1px solid ${
                  theme[0] === "dark" ? "#FCFCFF" : "black"
                }`,
              }}
            >
              {avgReturnForex(
                trades.filter(
                  (t) => t.outcomePC === "Win" || t.outcomeIC === "Win"
                )
              )}
            </td>
            <td
              style={{
                color: breakeven,
                borderBottom: `1px solid ${
                  theme[0] === "dark" ? "#FCFCFF" : "black"
                }`,
              }}
            >
              {avgReturnForex(
                trades.filter(
                  (t) =>
                    t.outcomePC === "Breakeven" || t.outcomeIC === "Breakeven"
                )
              )}
            </td>
            <td
              style={{
                color: loss,
                borderBottom: `1px solid ${
                  theme[0] === "dark" ? "#FCFCFF" : "black"
                }`,
              }}
            >
              {avgReturnForex(
                trades.filter(
                  (t) => t.outcomePC === "Loss" || t.outcomeIC === "Loss"
                )
              )}
            </td>
          </tr>
          <tr style={{ textAlign: "center" }}></tr>

          <tr style={{ textAlign: "center" }}>
            <th style={{ fontWeight: "400" }} scope="row"></th>
            <th style={{ fontWeight: "300" }} scope="row">
              Stop
            </th>
            <td style={{ color: win }}>
              {avgStopIndices(
                trades.filter(
                  (t) => t.outcomePC === "Win" || t.outcomeIC === "Win"
                )
              )}
            </td>
            <td style={{ color: breakeven }}>
              {avgStopIndices(
                trades.filter(
                  (t) =>
                    t.outcomePC === "Breakeven" || t.outcomeIC === "Breakeven"
                )
              )}
            </td>
            <td style={{ color: loss }}>
              {avgStopIndices(
                trades.filter(
                  (t) => t.outcomePC === "Loss" || t.outcomeIC === "Loss"
                )
              )}
            </td>
          </tr>
          <tr style={{ textAlign: "center" }}>
            <th style={{ fontWeight: "400" }} scope="row">
              Indices
            </th>
            <th style={{ fontWeight: "300" }} scope="row">
              Duration
            </th>

            <td style={{ color: win }}>
              {" "}
              {avgDurIndices(
                trades.filter(
                  (t) => t.outcomePC === "Win" || t.outcomeIC === "Win"
                )
              )}
            </td>
            <td style={{ color: breakeven }}>
              {" "}
              {avgDurIndices(
                trades.filter(
                  (t) =>
                    t.outcomePC === "Breakeven" || t.outcomeIC === "Breakeven"
                )
              )}
            </td>
            <td style={{ color: loss }}>
              {" "}
              {avgDurIndices(
                trades.filter(
                  (t) => t.outcomePC === "Loss" || t.outcomeIC === "Loss"
                )
              )}
            </td>
          </tr>
          <tr style={{ textAlign: "center" }}>
            <th style={{ fontWeight: "400" }} scope="row"></th>
            <th style={{ fontWeight: "300" }} scope="row">
              Time
            </th>

            <td style={{ color: win }}>
              {avgTimeIndices(
                trades.filter(
                  (t) => t.outcomePC === "Win" || t.outcomeIC === "Win"
                )
              )}
            </td>
            <td style={{ color: breakeven }}>
              {avgTimeIndices(
                trades.filter(
                  (t) =>
                    t.outcomePC === "Breakeven" || t.outcomeIC === "Breakeven"
                )
              )}
            </td>
            <td style={{ color: loss }}>
              {avgTimeIndices(
                trades.filter(
                  (t) => t.outcomePC === "Loss" || t.outcomeIC === "Loss"
                )
              )}
            </td>
          </tr>
          <tr
            style={{
              textAlign: "center",
              borderBottom: `1px solid ${
                theme[0] === "dark" ? "#FCFCFF" : "black"
              }`,
            }}
          >
            <th style={{ fontWeight: "400" }} scope="row"></th>
            <th style={{ fontWeight: "400" }} scope="row">
              Return
            </th>

            <td
              style={{
                color: win,
                borderBottom: `1px solid ${
                  theme[0] === "dark" ? "#FCFCFF" : "black"
                }`,
              }}
            >
              {avgReturnIndices(
                trades.filter(
                  (t) => t.outcomePC === "Win" || t.outcomeIC === "Win"
                )
              )}
            </td>
            <td
              style={{
                color: breakeven,
                borderBottom: `1px solid ${
                  theme[0] === "dark" ? "#FCFCFF" : "black"
                }`,
              }}
            >
              {avgReturnIndices(
                trades.filter(
                  (t) =>
                    t.outcomePC === "Breakeven" || t.outcomeIC === "Breakeven"
                )
              )}
            </td>
            <td
              style={{
                color: loss,
                borderBottom: `1px solid ${
                  theme[0] === "dark" ? "#FCFCFF" : "black"
                }`,
              }}
            >
              {avgReturnIndices(
                trades.filter(
                  (t) => t.outcomePC === "Loss" || t.outcomeIC === "Loss"
                )
              )}
            </td>
          </tr>

          <tr style={{ textAlign: "center" }}></tr>
          <tr style={{ textAlign: "center" }}>
            <th style={{ fontWeight: "400" }} scope="row"></th>
            <th style={{ fontWeight: "300" }} scope="row">
              Stop
            </th>
            <td style={{ color: win }}>
              {avgCommodities(
                trades.filter(
                  (t) => t.outcomePC === "Win" || t.outcomeIC === "Win"
                )
              )}
            </td>
            <td style={{ color: breakeven }}>
              {avgCommodities(
                trades.filter(
                  (t) =>
                    t.outcomePC === "Breakeven" || t.outcomeIC === "Breakeven"
                )
              )}
            </td>
            <td style={{ color: loss }}>
              {avgCommodities(
                trades.filter(
                  (t) => t.outcomePC === "Loss" || t.outcomeIC === "Loss"
                )
              )}
            </td>
          </tr>
          <tr style={{ textAlign: "center" }}>
            <th style={{ fontWeight: "400" }} scope="row">
              Commodities
            </th>
            <th style={{ fontWeight: "300" }} scope="row">
              Duration
            </th>

            <td style={{ color: win }}>
              {" "}
              {avgDurCommodities(
                trades.filter(
                  (t) => t.outcomePC === "Win" || t.outcomeIC === "Win"
                )
              )}
            </td>
            <td style={{ color: breakeven }}>
              {" "}
              {avgDurCommodities(
                trades.filter(
                  (t) =>
                    t.outcomePC === "Breakeven" || t.outcomeIC === "Breakeven"
                )
              )}
            </td>
            <td style={{ color: loss }}>
              {" "}
              {avgDurCommodities(
                trades.filter(
                  (t) => t.outcomePC === "Loss" || t.outcomeIC === "Loss"
                )
              )}
            </td>
          </tr>
          <tr style={{ textAlign: "center" }}>
            <th style={{ fontWeight: "400" }} scope="row"></th>
            <th style={{ fontWeight: "300" }} scope="row">
              Time
            </th>

            <td style={{ color: win }}>
              {avgTimeCommodities(
                trades.filter(
                  (t) => t.outcomePC === "Win" || t.outcomeIC === "Win"
                )
              )}
            </td>
            <td style={{ color: breakeven }}>
              {avgTimeCommodities(
                trades.filter(
                  (t) =>
                    t.outcomePC === "Breakeven" || t.outcomeIC === "Breakeven"
                )
              )}
            </td>
            <td style={{ color: loss }}>
              {avgTimeCommodities(
                trades.filter(
                  (t) => t.outcomePC === "Loss" || t.outcomeIC === "Loss"
                )
              )}
            </td>
          </tr>
          <tr
            style={{
              textAlign: "center",
              borderBottom: `1px solid ${
                theme[0] === "dark" ? "#FCFCFF" : "black"
              }`,
            }}
          >
            <th style={{ fontWeight: "400" }} scope="row"></th>
            <th style={{ fontWeight: "300" }} scope="row">
              Return
            </th>

            <td
              style={{
                color: win,
                borderBottom: `1px solid ${
                  theme[0] === "dark" ? "#FCFCFF" : "black"
                }`,
              }}
            >
              {avgReturnCommodities(
                trades.filter(
                  (t) => t.outcomePC === "Win" || t.outcomeIC === "Win"
                )
              )}
            </td>
            <td
              style={{
                color: breakeven,
                borderBottom: `1px solid ${
                  theme[0] === "dark" ? "#FCFCFF" : "black"
                }`,
              }}
            >
              {avgReturnCommodities(
                trades.filter(
                  (t) =>
                    t.outcomePC === "Breakeven" || t.outcomeIC === "Breakeven"
                )
              )}
            </td>
            <td
              style={{
                color: loss,
                borderBottom: `1px solid ${
                  theme[0] === "dark" ? "#FCFCFF" : "black"
                }`,
              }}
            >
              {avgReturnCommodities(
                trades.filter(
                  (t) => t.outcomePC === "Loss" || t.outcomeIC === "Loss"
                )
              )}
            </td>
          </tr>
          <tr style={{ textAlign: "center" }}></tr>
          <tr style={{ textAlign: "center" }}>
            <th style={{ fontWeight: "400" }} scope="row"></th>
            <th style={{ fontWeight: "300" }} scope="row">
              Stop
            </th>
            <td style={{ color: win }}>
              {avgStopCryto(
                trades.filter(
                  (t) => t.outcomePC === "Win" || t.outcomeIC === "Win"
                )
              )}
            </td>
            <td style={{ color: breakeven }}>
              {avgStopCryto(
                trades.filter(
                  (t) =>
                    t.outcomePC === "Breakeven" || t.outcomeIC === "Breakeven"
                )
              )}
            </td>
            <td style={{ color: loss }}>
              {avgStopCryto(
                trades.filter(
                  (t) => t.outcomePC === "Loss" || t.outcomeIC === "Loss"
                )
              )}
            </td>
          </tr>
          <tr style={{ textAlign: "center" }}>
            <th style={{ fontWeight: "400" }} scope="row">
              Crypto
            </th>
            <th style={{ fontWeight: "300" }} scope="row">
              Duration
            </th>

            <td style={{ color: win }}>
              {avgDurCrypto(
                trades.filter(
                  (t) => t.outcomePC === "Win" || t.outcomeIC === "Win"
                )
              )}
            </td>
            <td style={{ color: breakeven }}>
              {avgDurCrypto(
                trades.filter(
                  (t) =>
                    t.outcomePC === "Breakeven" || t.outcomeIC === "Breakeven"
                )
              )}
            </td>
            <td style={{ color: loss }}>
              {avgDurCrypto(
                trades.filter(
                  (t) => t.outcomePC === "Loss" || t.outcomeIC === "Loss"
                )
              )}
            </td>
          </tr>
          <tr style={{ textAlign: "center" }}>
            <th style={{ fontWeight: "400" }} scope="row"></th>
            <th style={{ fontWeight: "300" }} scope="row">
              Time
            </th>

            <td style={{ color: win }}>
              {avgTimeCrypto(
                trades.filter(
                  (t) => t.outcomePC === "Win" || t.outcomeIC === "Win"
                )
              )}
            </td>
            <td style={{ color: breakeven }}>
              {avgTimeCrypto(
                trades.filter(
                  (t) =>
                    t.outcomePC === "Breakeven" || t.outcomeIC === "Breakeven"
                )
              )}
            </td>
            <td style={{ color: loss }}>
              {avgTimeCrypto(
                trades.filter(
                  (t) => t.outcomePC === "Loss" || t.outcomeIC === "Loss"
                )
              )}
            </td>
          </tr>
          <tr style={{ textAlign: "center" }}>
            <th style={{ fontWeight: "400" }} scope="row"></th>
            <th style={{ fontWeight: "300" }} scope="row">
              Return
            </th>
            <td style={{ color: win }}>
              {avgReturnCrypto(
                trades.filter(
                  (t) => t.outcomePC === "Win" || t.outcomeIC === "Win"
                )
              )}
            </td>
            <td style={{ color: breakeven }}>
              {avgReturnCrypto(
                trades.filter(
                  (t) =>
                    t.outcomePC === "Breakeven" || t.outcomeIC === "Breakeven"
                )
              )}
            </td>
            <td style={{ color: loss }}>
              {avgReturnCrypto(
                trades.filter(
                  (t) => t.outcomePC === "Loss" || t.outcomeIC === "Loss"
                )
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Stats;
